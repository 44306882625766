import { ApolloClient, OnSubscriptionDataOptions } from '@apollo/client';
import { AssetDriverCurrentHosEventPayloadFragment } from '@generated/subscriptionFragments/assetDriverCurrentHosEvent';
import { AvailableCapacityEventPayloadFragment } from '@generated/subscriptionFragments/availableCapacityEvent';
import { BaseEventPayloadFragment } from '@generated/subscriptionFragments/baseEvent';
import { BookingEventPayloadFragment } from '@generated/subscriptionFragments/bookingEvent';
import { BookingFailureEventPayloadFragment } from '@generated/subscriptionFragments/bookingFailureEvent';
import { BookingOrchestrationStatusEventPayloadFragment } from '@generated/subscriptionFragments/bookingOrchestrationStatusEvent';
import { CarrierMatchEventPayloadFragment } from '@generated/subscriptionFragments/carrierMatchEvent';
import { ClientExceptionRecordEventPayloadFragment } from '@generated/subscriptionFragments/clientExceptionRecordEvent';
import { CommissionsRecalculatedEventPayloadFragment } from '@generated/subscriptionFragments/commissionsRecalculatedEvent';
import { CostLineItemEventPayloadFragment } from '@generated/subscriptionFragments/costLineItemEvent';
import { DocumentGenerateEventPayloadFragment } from '@generated/subscriptionFragments/documentGenerateEvent';
import { DriverAssignmentEventPayloadFragment } from '@generated/subscriptionFragments/driverAssignmentEventPayload';
import { DriverMessagingEventV3PayloadFragment } from '@generated/subscriptionFragments/driverMessagingEventV3';
import { DriverMessagingNewMessageEventPayloadFragment } from '@generated/subscriptionFragments/driverMessagingNewMessageEvent';
import { DriverMessagingNewMessageEventV2PayloadFragment } from '@generated/subscriptionFragments/driverMessagingNewMessageEventV2';
import { DriverPreplanEventPayloadFragment } from '@generated/subscriptionFragments/driverPreplanEventPayload';
import { EdiTransactionStorageEventPayloadFragment } from '@generated/subscriptionFragments/ediTransactionStorageEventPayload';
import { IncidentEventPayloadFragment } from '@generated/subscriptionFragments/incidentsEvent';
import { LinkedRouteEventPayloadFragment } from '@generated/subscriptionFragments/linkedRouteEvent';
import { LoadEmrEventPayloadFragment } from '@generated/subscriptionFragments/loadEMRMessageEvent';
import { LoadStatusEventPayloadFragment } from '@generated/subscriptionFragments/loadStatusEvent';
import { ProcurementMatchActionTakenEventPayloadFragment } from '@generated/subscriptionFragments/matchActionTakenEventPayload';
import { MessagingNotificationStatusEventPayloadFragment } from '@generated/subscriptionFragments/messagingNotificationStatusEvent';
import { MinionRefreshEventPayloadFragment } from '@generated/subscriptionFragments/minionRefreshEvent';
import { OfferEventPayloadFragment } from '@generated/subscriptionFragments/offerEvent';
import { PatternOrderApplicationStatusEventPayloadFragment } from '@generated/subscriptionFragments/patternOrderApplicationStatusEvent';
import { PatternOrderMatchEventPayloadFragment } from '@generated/subscriptionFragments/patternOrderMatchEvent';
import { PostingChangeEventPayloadFragment } from '@generated/subscriptionFragments/postingChangeEvent';
import { PricingRateEventPayloadFragment } from '@generated/subscriptionFragments/pricingRateEvent';
import { RateConfirmationChangeEventPayloadFragment } from '@generated/subscriptionFragments/rateConfirmationChangeEvent';
import { RateLineItemEventPayloadFragment } from '@generated/subscriptionFragments/rateLineItemEvent';
import { RepeatLoadEventPayloadFragment } from '@generated/subscriptionFragments/repeatLoadEvent';
import { RouteLockingEventPayloadFragment } from '@generated/subscriptionFragments/routeLockingEvent';
import { RouteMaxCostEventPayloadFragment } from '@generated/subscriptionFragments/routeMaxCostEvent';
import { RouteStatusEventPayloadFragment } from '@generated/subscriptionFragments/routeStatusEvent';
import { RouteVendorEventPayloadFragment } from '@generated/subscriptionFragments/routeVendorEvent';
import { SubStopExecutionEventPayloadFragment } from '@generated/subscriptionFragments/subStopExecutionEventPayload';
import { TenderPlanEventPayloadFragment } from '@generated/subscriptionFragments/tenderPlanEvent';
import { TenderPlanRouteEventPayloadFragment } from '@generated/subscriptionFragments/tenderPlanRouteEvent';
import { TrackingStatusEventPayloadFragment } from '@generated/subscriptionFragments/trackingStatusEvent';
import { TrackingUpdateEventPayloadFragment } from '@generated/subscriptionFragments/trackingUpdateEvent';
import { TruckPostingMatchEventPayloadFragment } from '@generated/subscriptionFragments/truckMatchEvent';
import { UserMatchEventPayloadFragment } from '@generated/subscriptionFragments/userMatchEvent';
import { EventType, ToastEvent } from '@generated/subscriptionTypes';
import {
  SubscribeSubscription,
  useSubscribeSubscription,
} from '@generated/subscriptions/subscribe';

// To add new EventTypes to the Hook add the new EventType to the EventHandlers
// interface
// and new typename to EventType mapping to the typeNameToEventType function.

type Sig<T extends unknown> = (event: T, client: ApolloClient<object>) => void;

interface EventHandlers {
  [EventType.LinkedRouteEvent]?: Sig<LinkedRouteEventPayloadFragment>;
  [EventType.LoadStatusEvent]?: Sig<LoadStatusEventPayloadFragment>;
  [EventType.RouteStatusEvent]?: Sig<RouteStatusEventPayloadFragment>;
  [EventType.RouteLockingEvent]?: Sig<RouteLockingEventPayloadFragment>;
  [EventType.Unknown]?: Sig<BaseEventPayloadFragment>;
  [EventType.TrackingStatusEvent]?: Sig<TrackingStatusEventPayloadFragment>;
  [EventType.DriverAssignmentEvent]?: Sig<DriverAssignmentEventPayloadFragment>;
  [EventType.BookingEvent]?: Sig<BookingEventPayloadFragment>;
  [EventType.RouteVendorEvent]?: Sig<RouteVendorEventPayloadFragment>;
  [EventType.CommissionsRecalculatedEvent]?: Sig<CommissionsRecalculatedEventPayloadFragment>;
  [EventType.CarrierMatchEvent]?: Sig<CarrierMatchEventPayloadFragment>;
  [EventType.UserMatchEvent]?: Sig<UserMatchEventPayloadFragment>;
  [EventType.TruckPostingMatchEvent]?: Sig<TruckPostingMatchEventPayloadFragment>;
  [EventType.ProcurementMatchActionTakenEvent]?: Sig<ProcurementMatchActionTakenEventPayloadFragment>;
  [EventType.SubStopExecutionEvent]?: Sig<SubStopExecutionEventPayloadFragment>;
  [EventType.TenderPlanEvent]?: Sig<TenderPlanEventPayloadFragment>;
  [EventType.TenderPlanRouteEvent]?: Sig<TenderPlanRouteEventPayloadFragment>;
  [EventType.TrackingUpdateEvent]?: Sig<TrackingUpdateEventPayloadFragment>;
  [EventType.ToastEvent]?: Sig<ToastEvent>;
  [EventType.OfferEvent]?: Sig<OfferEventPayloadFragment>;
  [EventType.RouteMaxCostEvent]?: Sig<RouteMaxCostEventPayloadFragment>;
  [EventType.PricingRateEvent]?: Sig<PricingRateEventPayloadFragment>;
  [EventType.PostingChangeEvent]?: Sig<PostingChangeEventPayloadFragment>;
  [EventType.RateConfirmationChangeEvent]?: Sig<RateConfirmationChangeEventPayloadFragment>;
  [EventType.CostLineItemEvent]?: Sig<CostLineItemEventPayloadFragment>;
  [EventType.RateLineItemEvent]?: Sig<RateLineItemEventPayloadFragment>;
  [EventType.ClientExceptionRecordEvent]?: Sig<ClientExceptionRecordEventPayloadFragment>;
  [EventType.IncidentEvent]?: Sig<IncidentEventPayloadFragment>;
  [EventType.MinionRefreshEvent]?: Sig<MinionRefreshEventPayloadFragment>;
  [EventType.MessagingNotificationStatusEvent]?: Sig<MessagingNotificationStatusEventPayloadFragment>;
  [EventType.PatternOrderApplicationStatusEvent]?: Sig<PatternOrderApplicationStatusEventPayloadFragment>;
  [EventType.PatternOrderMatchEvent]?: Sig<PatternOrderMatchEventPayloadFragment>;
  [EventType.RepeatLoadEvent]?: Sig<RepeatLoadEventPayloadFragment>;
  [EventType.AvailableCapacityEvent]?: Sig<AvailableCapacityEventPayloadFragment>;
  [EventType.EdiTransactionStorageEvent]?: Sig<EdiTransactionStorageEventPayloadFragment>;
  [EventType.DocumentGenerateEvent]?: Sig<DocumentGenerateEventPayloadFragment>;
  [EventType.BookingFailureEvent]?: Sig<BookingFailureEventPayloadFragment>;
  [EventType.DriverMessagingNewMessageEvent]?: Sig<DriverMessagingNewMessageEventPayloadFragment>;
  [EventType.DriverMessagingNewMessageV2Event]?: Sig<DriverMessagingNewMessageEventV2PayloadFragment>;
  [EventType.DriverMessagingEventV3]?: Sig<DriverMessagingEventV3PayloadFragment>;
  [EventType.LoadEmrEvent]?: Sig<LoadEmrEventPayloadFragment>;
  [EventType.PreplanEvent]?: Sig<DriverPreplanEventPayloadFragment>;
  [EventType.AssetDriverCurrentHosEvent]?: Sig<AssetDriverCurrentHosEventPayloadFragment>;
  [EventType.BookingOrchestrationStatusEvent]?: Sig<BookingOrchestrationStatusEventPayloadFragment>;
}

const typeNameToEventType: (
  typename: SubscribeSubscription['subscribe']['__typename']
) => keyof EventHandlers = (typename) => {
  switch (typename) {
    case 'DriverAssignmentEvent':
      return EventType.DriverAssignmentEvent;
    case 'LinkedRouteEvent':
      return EventType.LinkedRouteEvent;
    case 'LoadStatusEvent':
      return EventType.LoadStatusEvent;
    case 'RouteStatusEvent':
      return EventType.RouteStatusEvent;
    case 'RouteLockingEvent':
      return EventType.RouteLockingEvent;
    case 'TrackingStatusEvent':
      return EventType.TrackingStatusEvent;
    case 'RouteVendorEvent':
      return EventType.RouteVendorEvent;
    case 'BookingEvent':
      return EventType.BookingEvent;
    case 'CommissionsRecalculatedEvent':
      return EventType.CommissionsRecalculatedEvent;
    case 'CarrierMatchEvent':
      return EventType.CarrierMatchEvent;
    case 'UserMatchEvent':
      return EventType.UserMatchEvent;
    case 'TruckPostingMatchEvent':
      return EventType.TruckPostingMatchEvent;
    case 'ProcurementMatchActionTakenEvent':
      return EventType.ProcurementMatchActionTakenEvent;
    case 'SubStopExecutionEvent':
      return EventType.SubStopExecutionEvent;
    case 'TenderPlanEvent':
      return EventType.TenderPlanEvent;
    case 'TenderPlanRouteEvent':
      return EventType.TenderPlanRouteEvent;
    case 'TrackingUpdateEvent':
      return EventType.TrackingUpdateEvent;
    case 'ToastEvent':
      return EventType.ToastEvent;
    case 'OfferEvent':
      return EventType.OfferEvent;
    case 'RouteMaxCostEvent':
      return EventType.RouteMaxCostEvent;
    case 'PostingChangeEvent':
      return EventType.PostingChangeEvent;
    case 'RateConfirmationChangeEvent':
      return EventType.RateConfirmationChangeEvent;
    case 'PricingRateEvent':
      return EventType.PricingRateEvent;
    case 'CostLineItemEvent':
      return EventType.CostLineItemEvent;
    case 'RateLineItemEvent':
      return EventType.RateLineItemEvent;
    case 'ClientExceptionRecordEvent':
      return EventType.ClientExceptionRecordEvent;
    case 'IncidentEvent':
      return EventType.IncidentEvent;
    case 'MinionRefreshEvent':
      return EventType.MinionRefreshEvent;
    case 'MessagingNotificationStatusEvent':
      return EventType.MessagingNotificationStatusEvent;
    case 'PatternOrderApplicationStatusEvent':
      return EventType.PatternOrderApplicationStatusEvent;
    case 'PatternOrderMatchEvent':
      return EventType.PatternOrderMatchEvent;
    case 'RepeatLoadEvent':
      return EventType.RepeatLoadEvent;
    case 'AvailableCapacityEvent':
      return EventType.AvailableCapacityEvent;
    case 'EdiTransactionStorageEvent':
      return EventType.EdiTransactionStorageEvent;
    case 'DocumentGenerateEvent':
      return EventType.DocumentGenerateEvent;
    case 'BookingFailureEvent':
      return EventType.BookingFailureEvent;
    case 'BookingOrchestrationStatusEvent':
      return EventType.BookingOrchestrationStatusEvent;
    case 'DriverMessagingNewMessageEvent':
      return EventType.DriverMessagingNewMessageEvent;
    case 'DriverMessagingNewMessageEventV2':
      return EventType.DriverMessagingNewMessageV2Event;
    case 'DriverMessagingEventV3':
      return EventType.DriverMessagingEventV3;
    case 'LoadEMREvent':
      return EventType.LoadEmrEvent;
    case 'PreplanEvent':
      return EventType.PreplanEvent;
    case 'AssetDriverCurrentHosEvent':
      return EventType.AssetDriverCurrentHosEvent;
    case 'BaseEvent':
    default:
      return EventType.Unknown;
  }
};

interface SubscriptionHookOptions {
  eventHandlers: EventHandlers;
  subscriptionIds?: string[];
  disable?: boolean;
}

/**
 * Hook to reduce duplicate code and simplify interacting with the subscription
 * service
 *
 * Example Usage:
 *
 * useSubscriptionService({
 *   disable: !useSubscription,
 *   subscriptionIds,
 *   eventHandlers: {
 *     [EventType.RouteLockingEvent]: (
 *       event: RouteLockingEventPayloadFragment
 *     ): void => {
 *       doRouteLockingEventStuff();
 *     },
 *     [EventType.LoadStatusEvent]: (
 *       event: LoadStatusEventPayloadFragment
 *     ): void => {
 *       doLoadStatusEventStuff()
 *     },
 *   },
 * });
 *
 * @param options The `eventHandler` object is used to determine which events
 *   to subscribe to and how to handle their events. Pass in the
 *   `subscriptionIds` to filter subscription events based on Ids (Not passing
 *   this will subscribe to all events)
 */
export const useSubscriptionService: (
  options: SubscriptionHookOptions
) => void = ({ eventHandlers, subscriptionIds, disable }) => {
  const onSubscriptionData = ({
    subscriptionData: { data },
    client,
  }: OnSubscriptionDataOptions<SubscribeSubscription>): void => {
    if (!data) {
      return;
    }

    const eventType = typeNameToEventType(data.subscribe.__typename);
    // TODO: Currently doing `as fixMe` to simplify addition of EventTypes in the future.
    // This could be a switch on the data.subscribe.__typename to enable type-guarding
    eventHandlers[eventType]?.(data.subscribe as fixMe, client);
  };

  // Pull EventTypes to subscribe to from EventHandler
  const eventTypes: EventType[] = Object.keys(eventHandlers) as EventType[];

  useSubscribeSubscription({
    variables: {
      ids: subscriptionIds,
      eventTypes,
    },
    onSubscriptionData: onSubscriptionData,
    // only enable subscription when there is data we want to fetch
    skip: disable || subscriptionIds?.length === 0,
  });
};
