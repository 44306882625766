import { submitMutation } from '@components/shared/mutation';
import { TabObj } from '@components/Tabs';
import { useCreateOrUpdateTabPreferenceMutation } from '@generated/mutations/createOrUpdateTabPreference';
import {
  GetTabPreferenceDocument,
  GetTabPreferenceQuery,
  GetTabPreferenceQueryVariables,
} from '@generated/queries/getTabPreference';
import { useLazyQueryWithDataPromise } from '@hooks/useLazyQueryWithDataPromise';
import {
  createContext,
  FC,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';

interface TabPreferencesContextManager {
  getTabPreferencesById: (tabId: string, tabs: fixMe[]) => void;
  tabPreferences?: Record<string, Array<TabObj<string>>>;
  handleSaveTabPreferences: (
    tabId: string,
    tabs: Array<TabObj<string>>
  ) => void;
}

const defaultSettings = {
  isVisible: true,
  default: false,
};

const TabPreferencesContext = createContext<TabPreferencesContextManager>({
  getTabPreferencesById: () => {},
  handleSaveTabPreferences: () => {},
  tabPreferences: {},
});

interface TabsResponseObj {
  id: string;
  default: boolean;
  isVisible: boolean;
  order: number;
  __typename?: 'Tab';
}

function mergeTabsById(
  encodedTabs: Array<TabObj<string>>,
  savedTabPreferences: Omit<TabsResponseObj, '__typename'>[]
): Array<TabObj<string>> {
  return encodedTabs.map((encodedTab) => {
    const tabFoundInSavedPreferences = savedTabPreferences.find(
      (item) => item.id === encodedTab.id
    );
    return {
      ...encodedTab,
      ...(tabFoundInSavedPreferences || defaultSettings),
    };
  });
}

const mapTabPreferences = (
  tabs: ReadonlyArray<TabsResponseObj>
): Array<Omit<TabsResponseObj, '__typename'>> => {
  return tabs.map(({ id, order, default: isDefault, isVisible }) => ({
    id,
    order,
    default: isDefault,
    isVisible,
  }));
};

export const TabPreferencesProvider: FC<anyOk> = ({ children }) => {
  const [tabPreferences, setTabPreferences] = useState<
    Record<string, Array<TabObj<string>>>
  >({});

  const getTabPreferenceById = useLazyQueryWithDataPromise<
    GetTabPreferenceQuery,
    GetTabPreferenceQueryVariables
  >(GetTabPreferenceDocument);

  const [handleCreateOrUpdateTabPreference] =
    useCreateOrUpdateTabPreferenceMutation();

  const getTabPreferencesById = useCallback(
    async (tabId: string, tabs: Array<TabObj<string>>): Promise<void> => {
      const savedTabPreferences = await getTabPreferenceById({
        variables: {
          tabGroup: {
            tabGroupId: tabId,
          },
        },
      });
      const responsedTabs = savedTabPreferences.data.getTabPreference?.filter(
        (item) => item?.tabGroupId === tabId
      );
      const mergedTabs = mergeTabsById(tabs, [
        ...(responsedTabs?.[0]?.tabs || []),
      ]);
      setTabPreferences((prefs: Record<string, Array<TabObj<string>>>) => ({
        ...prefs,
        [tabId]: mergedTabs,
      }));
    },
    [getTabPreferenceById]
  );

  const handleSaveTabPreferences = useCallback(
    async (tabId: string, tabs: Array<TabObj<string>>): Promise<void> => {
      const encodedTabs = tabs.map((tab, idx) => ({
        id: tab.id,
        default: tab.default ?? false,
        isVisible: tab.isVisible ?? true,
        order: tab.order ?? idx,
      }));

      const response = await submitMutation(() =>
        handleCreateOrUpdateTabPreference({
          variables: {
            input: {
              tabGroupId: tabId,
              tabs: encodedTabs,
            },
          },
        })
      );
      if (response?.data?.createOrUpdateTabPreference?.tabGroupId) {
        const tabPrefs = mapTabPreferences(
          response.data.createOrUpdateTabPreference.tabs
        );
        const mergedTabs = mergeTabsById(
          tabPreferences[`${tabId}`] as fixMe,
          tabPrefs
        );
        setTabPreferences((prefs: Record<string, Array<TabObj<string>>>) => ({
          ...prefs,
          [tabId]: mergedTabs,
        }));
      } else {
        setTabPreferences((prefs: Record<string, Array<TabObj<string>>>) => ({
          ...prefs,
          [tabId]: tabs,
        }));
      }
    },
    [handleCreateOrUpdateTabPreference, tabPreferences]
  );

  const value: TabPreferencesContextManager = useMemo(() => {
    return {
      getTabPreferencesById,
      tabPreferences,
      handleSaveTabPreferences,
    };
  }, [getTabPreferencesById, tabPreferences, handleSaveTabPreferences]);

  return (
    <TabPreferencesContext.Provider value={value}>
      {children}
    </TabPreferencesContext.Provider>
  );
};

export const useTabPreferences = (): TabPreferencesContextManager => {
  const context = useContext(TabPreferencesContext);
  if (!context) {
    throw new Error(
      'useTabPreferences must be used within a TabPreferencesProvider'
    );
  }
  return context;
};
