import { Icon } from '@components/Icon';
import { useHasPermissionFromContext } from '@components/PermissionsContext';
import { getInnerText } from '@components/Table/util/getInnerText';
import { INPUT_HEIGHT } from '@components/Theme/constants';
import { Tooltip } from '@components/Tooltip';
import { CSSObject } from '@emotion/styled';
import { useDebouncedFn } from '@hooks/useDebouncedFn';
import { useFullstoryElement } from '@hooks/useFullstory';
import { useTheme } from '@hooks/useTheme';
import { FS_MASK, FullStoryElements, FullStoryType } from '@utils/fullstory';
import { win } from '@utils/win';
import { AUTOCOMPLETE } from '@utils/zIndex';
import Downshift, {
  ControllerStateAndHelpers,
  DownshiftState,
  GetItemPropsOptions,
  StateChangeOptions,
} from 'downshift';
import { isFunction, isString, noop, omit, toLower, trim } from 'lodash-es';
import {
  HTMLProps,
  KeyboardEvent,
  MutableRefObject,
  ReactElement,
  ReactNode,
  forwardRef,
  useEffect,
  useRef,
  useState,
} from 'react';
import { createPortal } from 'react-dom';
import { usePopper } from 'react-popper';
import { Writable } from 'type-fest';
import { ReadOnlyField } from '../Field/ReadOnlyField';
import { Label } from '../FieldLabel';
import { AUTOCOMPLETE_OFF_VALUE, Input } from '../Input';
import { useMaxItemContainerWidth } from './useMaxItemContainerWidth';

export interface Shell<ItemType> {
  label: string | ((item?: Maybe<fixMe>) => ReactNode);
  value: ItemType | undefined;
  /**  used for React key attr, falls back to label if no id provided */
  id?: string | number;
  hidden?: boolean;
  /** Render the item, but disallow selection */
  disabled?: boolean;
  newLabel?: string;
  hideCheckbox?: boolean;
  /** Only used for title attr. Use label for item rendering. Defaults to label if not specified. */
  titleAttr?: string;
}

type ItemToString<ItemType> = (item: Shell<ItemType> | null) => string;

export type RenderItemHelpers<ItemType> = ControllerStateAndHelpers<
  Shell<ItemType>
> &
  FullStoryType & {
    defaultItemStyles: CSSObject;
    key: string;
    item: Shell<ItemType>;
    isHighlighted: boolean;
    index: number;
    itemProps: Record<string, unknown>;
    getItemProps: (options: GetItemPropsOptions<Shell<ItemType>>) => unknown;
    disabled?: boolean;
    newLabel?: string;
    hideCheckbox?: boolean;
    fsItemElement?: string;
  };

interface ShowAddNewItemShell<ItemType> extends Omit<Shell<ItemType>, 'label'> {
  /** Internal implementation prop, consumers do not need to use this. */
  addNew?: true;
  prefix?: string;
  label?: string;
}

export interface Props<ItemType> extends FullStoryType {
  name?: string;
  disabled?: boolean;
  readOnly?: boolean;
  id?: string;
  initialSelectedItem?: Shell<ItemType>;
  onKeyDown?: (event: KeyboardEvent<HTMLDivElement>) => void;
  onBlur?: (event: React.FocusEvent<HTMLDivElement>) => void;
  inputProps?: Omit<
    HTMLProps<HTMLInputElement> & { 'data-testid'?: string },
    'disabled'
  >;
  items: ReadonlyArray<Shell<ItemType>>;
  itemToString?: ItemToString<ItemType>;
  label?: ReactNode;
  loading?: boolean | string;
  /** Defaults to true. Typically this is what you want as you will not have any results yet. */
  showLoadingMessageOnFirstLoading?: boolean;
  /** This is the event you want to tap into to do asynchronous work. You must provide your own debounced function if you want to do that. Use the useDebouncedFn hook. */
  onInputValueChange?: (
    inputValue: string,
    stateAndHelpers: ControllerStateAndHelpers<Shell<ItemType>>
  ) => void;
  onChange: (
    item: Shell<ItemType> | null,
    helpers?: ControllerStateAndHelpers<Shell<ItemType>>
  ) => void;
  openMenuOnFocus?: boolean;
  /** @deprecated Use the `label` callback function method on individual items to customize display. See AutoComplete story code for example. */
  DEPRECATEDrenderItem?: (helpers: RenderItemHelpers<ItemType>) => ReactNode;
  /** Defaults to false */
  showSearchIcon?: boolean;
  selectedItem?: Maybe<Shell<ItemType>>;
  maxItemSelectLimit?: number;
  selectedItemCount?: number;
  filterOnLabel?: boolean;
  disableSelectOnTab?: boolean;
  initialIsOpen?: boolean;
  isOpen?: boolean;
  downshiftStateReducer?: (
    state: DownshiftState<Shell<ItemType>>,
    changes: StateChangeOptions<Shell<ItemType>>
  ) => Partial<StateChangeOptions<Shell<ItemType>>>;
  /** Restricts item container to width of parent input, incurring further possibility of word-break */
  restrictItemContainerWidth?: boolean;
  renderDropdownInPopper?: boolean;
  /** The item selection area will appear above the button */
  dropup?: boolean;
  popperZIndex?: number;
  tabIndex?: number;
  triggerInputChangeOnEmpty?: boolean;
  ref?: MutableRefObject<HTMLButtonElement> | null;
  showAddNewItem?: (kwargs: {
    listItems: ReadonlyArray<Shell<ItemType>>;
    inputValue?: Maybe<string>;
  }) => void | ShowAddNewItemShell<unknown>;
  showAddNewItemAtTop?: boolean;
  /** An implementation prop to always force a specific input value from the consuming component. Used mostly by MultiSelectAutoComplete and not needed for normal implementations. */
  controlledInputValue?: boolean;
  defaultHighlightedIndex?: number;
  fsItemElement?: string;
  fsSearchElement?: string;
}

export const getStringFromLabelFunc = (
  item: string | Maybe<Partial<Shell<unknown>>>
): string => {
  if (isString(item)) {
    return item;
  }
  if (isFunction(item?.label)) {
    let candidate: ReactNode = '';
    if (item && item.label) {
      candidate = item.label(item.value);
    }
    if (isString(candidate)) {
      return candidate;
    } else {
      // TODO this function is EXPENSIVE. It is best to use a string label if at all possible. This is a fallback for a react node label which takes time to compute.
      return getInnerText((<>{candidate}</>) as fixMe);
    }
  }
  return item?.label || '';
};

export function getStringFromItem<ItemType>(
  item: Maybe<Shell<ItemType>>,
  itemToString?: ItemToString<ItemType>
): string {
  if (item) {
    if (itemToString) {
      return itemToString(item);
    } else if (isString(item.label)) {
      return item.label;
    } else if (isFunction(item.label)) {
      const label: anyOk = item.label();
      return isString(label)
        ? label
        : label?.props?.children && isString(label?.props?.children)
        ? label?.props?.children
        : '';
    } else {
      return '';
    }
  }
  return '';
}

/** Characters to ignore when matching the input value to the list of results */
const charsToIgnoreRegex = /(,|\.|-|'|")/gi;

export function checkFilterMatch<ItemType>(
  item: Shell<ItemType>,
  inputValue: string,
  itemToString?: ItemToString<ItemType>
): boolean {
  const coercedLabel = toLower(getStringFromItem(item, itemToString))
    .trim()
    .replace(charsToIgnoreRegex, '');

  const coercedInput = toLower(inputValue)
    .trim()
    .replace(charsToIgnoreRegex, '');
  return coercedLabel.includes(coercedInput);
}

const ITEM_MIN_HEIGHT = 32;

// ts-unused-exports:disable-next-line
export const itemStyles: CSSObject = {
  margin: 0,
  padding: '8px 8px',
  minHeight: ITEM_MIN_HEIGHT,
};

export const ITEM_CONTAINER_MAX_HEIGHT = 250;
export const ITEM_CONTAINER_VERT_MARGIN = 2;
export const SELECTALL_KEY = 'selectall';
export const SELECTNONE_KEY = 'selectnone';

interface ItemContainerProps {
  maxWidth?: number;
  minWidth?: number | string;
  disableClickBubble?: boolean;
}

export const ItemContainer = forwardRef<HTMLUListElement, ItemContainerProps>(
  ({ children, maxWidth, minWidth, disableClickBubble, ...rest }, ref) => {
    const { card, gray, contextMenu } = useTheme();
    return (
      <ul
        css={{
          background: contextMenu.background,
          border: `1px solid ${gray[95]}`,
          borderRadius: 2,
          boxShadow: card.boxShadow,
          listStyleType: 'none',
          margin: 0,
          marginTop: ITEM_CONTAINER_VERT_MARGIN,
          maxHeight: ITEM_CONTAINER_MAX_HEIGHT,
          maxWidth: maxWidth || '100%',
          minWidth: minWidth || '100%',
          overflow: 'auto',
          padding: 0,
          position: 'absolute',
          top: '100%',
          width: 'max-content',
          zIndex: AUTOCOMPLETE,
          scrollbarColor: 'auto',
        }}
        onClick={
          disableClickBubble ? (e): void => e.stopPropagation() : undefined
        }
        {...rest}
        ref={ref}
      >
        {children}
      </ul>
    );
  }
);

export const Item = forwardRef<
  anyOk,
  {
    isHighlighted: boolean;
    titleAttr: string;
    children: ReactNode;
    disabled?: boolean;
    onClick?: () => void;
  } & FullStoryType
>(
  (
    {
      children,
      titleAttr: itemLabel,
      isHighlighted,
      disabled,
      fsElement,
      fsType,
      fsParent,
      fsName,
      ...rest
    },
    ref
  ) => {
    const {
      colors: { primary, text, disabledText },
      card,
    } = useTheme();
    const { getFsComponentProps } = useFullstoryElement();

    return (
      <li
        data-disabled={Boolean(disabled).toString()}
        title={itemLabel}
        css={{
          ...itemStyles,
          cursor: disabled ? 'default' : 'pointer',
          userSelect: 'none',
          backgroundColor: isHighlighted ? primary : 'inherit',
          color: isHighlighted
            ? card.background
            : disabled
            ? disabledText
            : text,
        }}
        {...rest}
        onClick={
          disabled
            ? (e): void => {
                if (disabled) {
                  e.preventDefault();
                }
              }
            : rest.onClick
        }
        ref={ref}
        {...getFsComponentProps({
          name: fsName,
          parent: fsParent,
          element: fsElement,
          type: fsType,
          status: disabled ? 'inActive' : 'active',
        })}
      >
        {children}
      </li>
    );
  }
);

// this is an example of a TS generic react component
export const AutoComplete = <ItemType extends unknown>(
  props: Props<ItemType>
): ReactElement => {
  const {
    defaultHighlightedIndex,
    disableSelectOnTab,
    downshiftStateReducer,
    filterOnLabel = false,
    initialIsOpen,
    initialSelectedItem,
    inputProps,
    isOpen: isOpenProp,
    readOnly,
    items,
    itemToString = getStringFromLabelFunc,
    label,
    loading,
    showLoadingMessageOnFirstLoading = true,
    name,
    onChange,
    onInputValueChange = noop,
    openMenuOnFocus = false,
    DEPRECATEDrenderItem: renderItem,
    restrictItemContainerWidth,
    maxItemSelectLimit,
    selectedItemCount = 0,
    selectedItem,
    showSearchIcon = false,
    renderDropdownInPopper,
    dropup,
    popperZIndex,
    triggerInputChangeOnEmpty = false,
    showAddNewItem,
    showAddNewItemAtTop = false,
    controlledInputValue,
    fsItemElement = FullStoryElements.FIELD_MENU_ITEM,
    fsSearchElement = FullStoryElements.FIELD_SEARCH_BAR,
    fsParent,
    fsType = 'autocomplete',
    fsName,
    ...restProps
  } = props;

  const { gray } = useTheme();
  const [isLoading, setLoading] = useState(false);
  const [loadingTimer, setLoadingTimer] = useState<number | null>(null);
  const [isTyping, setIsTyping] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const itemContainerRef = useRef<HTMLDivElement>(null);
  const [inputValueStateRaw, setInputValueState] = useState(
    (props.inputProps?.value as string) ?? ''
  );

  const inputValueState = controlledInputValue
    ? (props.inputProps?.value as string)
    : inputValueStateRaw;

  const parentContainerRef = useRef();
  const maxItemContainerWidth = useMaxItemContainerWidth(
    parentContainerRef,
    restrictItemContainerWidth
  );

  const {
    styles: popperStyles,
    attributes: popperAttributes,
    state: popperState,
    update: popperUpdate,
  } = usePopper(inputRef.current, itemContainerRef.current, {
    placement: 'bottom-start',
    strategy: 'fixed',
  });

  const [userCanEdit, permissionScope] = useHasPermissionFromContext();

  // It is useful to only show the loading message that the consumer provides after N milliseconds
  // Otherwise you get unhelpful loading jank, especially if the requests run quick (< 500ms)
  useEffect(() => {
    clearTimeout(loadingTimer || 0);
    // if it is the first time that we see a loading prop, set immediately, as we probably don't have any results yet.
    if (loading && loadingTimer === null && showLoadingMessageOnFirstLoading) {
      setLoading(true);
    }
    // if the loadingTimer is null, that means we haven't seen a loading=true prop yet. So the component may have just mounted with no user input.
    else if (loadingTimer === null) {
      return;
    }

    if (loading) {
      const timer = win.setTimeout(() => setLoading(true), 1500);
      setLoadingTimer(timer);
    } else {
      setLoading(false);
      setLoadingTimer(0);
    }
    return (): void => clearTimeout(loadingTimer || 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  const debouncedSetIsTyping = useDebouncedFn(setIsTyping, 100, []);

  const isReadOnly = !userCanEdit || readOnly;

  if (isReadOnly) {
    return (
      <>
        {label && <Label>{label}</Label>}
        <ReadOnlyField data-scope={permissionScope} name={name}>
          {(isFunction(selectedItem?.label) &&
            selectedItem?.label(selectedItem?.value)) ||
            selectedItem?.label ||
            initialSelectedItem?.label ||
            inputProps?.value}
        </ReadOnlyField>
      </>
    );
  }

  const allItemsToRender: Writable<Shell<ItemType>>[] = filterOnLabel
    ? items.filter((item) => {
        return (
          item.value === SELECTALL_KEY ||
          checkFilterMatch(
            item,
            trim(inputValueState || '') || '',
            itemToString
          )
        );
      })
    : [...items];

  return (
    <Downshift
      onChange={onChange}
      itemToString={itemToString}
      defaultHighlightedIndex={defaultHighlightedIndex ?? 0}
      initialSelectedItem={initialSelectedItem}
      initialIsOpen={initialIsOpen}
      isOpen={isOpenProp}
      scrollIntoView={(node): void => {
        node?.scrollIntoView({ block: 'end' });
      }}
      selectedItemChanged={(prev, next): boolean => {
        if (prev?.id) {
          return prev.id !== next?.id;
        } else if (prev?.label) {
          return itemToString(prev) !== itemToString(next);
        }
        // this is the default per Downshift, but we might want to change this to _.isEqual ?
        return prev !== next;
      }}
      onInputValueChange={(value, helpers): void => {
        if (!value && helpers.selectedItem?.label) {
          helpers.clearSelection();
        }
        if (
          (helpers.selectedItem?.label || '') !== value ||
          (triggerInputChangeOnEmpty && value === '')
        ) {
          onInputValueChange(value, helpers);
        }
        setIsTyping(true);
        debouncedSetIsTyping(false);
        setInputValueState(value);
      }}
      selectedItem={selectedItem}
      stateReducer={(
        state,
        rawChanges
      ): Partial<StateChangeOptions<Shell<ItemType>>> => {
        let changes = rawChanges;
        if (
          changes.type ===
          Downshift.stateChangeTypes.controlledPropUpdatedSelectedItem
        ) {
          changes = {
            ...changes,
            inputValue: itemToString(selectedItem || null),
          };
        }
        const allItemsDisabled = allItemsToRender.every(
          (item) => item.disabled
        );

        // fix for improper highlightedIndex indexing, especially when some items are disabled.
        if (changes.type === Downshift.stateChangeTypes.keyDownArrowDown) {
          const foundItem = allItemsToRender[changes.highlightedIndex ?? -1];
          if (foundItem && foundItem.disabled && !allItemsDisabled) {
            // find the next non-disabled item
            let nextIndex = changes.highlightedIndex ?? -1;
            while (allItemsToRender[nextIndex]?.disabled) {
              // if we are at the end of the list, go to the beginning
              if (nextIndex === allItemsToRender.length - 1) {
                nextIndex = 0;
              } else {
                nextIndex++;
              }
            }
            if (nextIndex !== changes.highlightedIndex) {
              changes.highlightedIndex = nextIndex;
            }
          }
        }

        // fix for improper highlightedIndex indexing, especially when some items are disabled.
        if (changes.type === Downshift.stateChangeTypes.keyDownArrowUp) {
          const foundItem = allItemsToRender[changes.highlightedIndex ?? -1];
          if (foundItem && foundItem.disabled && !allItemsDisabled) {
            // find the next non-disabled item
            let nextIndex = changes.highlightedIndex ?? -1;
            while (allItemsToRender[nextIndex]?.disabled) {
              // if we are at the beginning of the list, go to the end
              if (nextIndex === 0) {
                nextIndex = allItemsToRender.length - 1;
              } else {
                nextIndex--;
              }
            }
            if (nextIndex !== changes.highlightedIndex) {
              changes.highlightedIndex = nextIndex;
            }
          }
        }

        if (downshiftStateReducer) {
          return downshiftStateReducer(state, changes);
        }
        return changes;
      }}
    >
      {(helpers): ReactNode => {
        const {
          clearSelection,
          getInputProps,
          getItemProps,
          getLabelProps,
          getMenuProps,
          getRootProps,
          highlightedIndex,
          inputValue,
          isOpen,
          selectHighlightedItem,
          openMenu,
        } = helpers;
        const generatedItem = showAddNewItem?.({
          listItems: allItemsToRender,
          inputValue,
        });

        if (
          generatedItem &&
          !allItemsToRender.find((i) => (i as anyOk)?.addNew)
        ) {
          generatedItem.addNew = true;
          generatedItem.newLabel = `${
            generatedItem?.prefix ?? 'Create'
          } ${inputValue}`;
          generatedItem.label = inputValue ?? '';

          if (showAddNewItemAtTop) {
            allItemsToRender?.unshift?.(generatedItem as Shell<ItemType>);
          } else {
            allItemsToRender?.push?.(generatedItem as Shell<ItemType>);
          }
        }

        const itemContainer = (
          <ItemContainer
            {...getMenuProps({}, { suppressRefError: true })}
            data-list-wrapper
            data-parentfield={name || inputProps?.name}
            css={{
              bottom: dropup ? `${INPUT_HEIGHT}px` : undefined,
              top: dropup ? 'unset' : undefined,
              // White icons on rollover.
              '[aria-selected=true] svg': {
                color: `${gray.white} !important`,
              },
            }}
            maxWidth={maxItemContainerWidth}
            minWidth={
              (renderDropdownInPopper &&
                popperState?.rects?.reference?.width) ||
              '100%'
            }
            disableClickBubble
          >
            {isLoading && (
              <li css={itemStyles} data-testid="autocomplete-loading-msg">
                {isString(loading) ? loading : 'Loading...'}
              </li>
            )}
            {allItemsToRender.map((item, index) => {
              const isHighlighted =
                !item.disabled && highlightedIndex === index;
              const key = String(item.id || itemToString(item));
              if (renderItem) {
                return renderItem({
                  ...helpers,
                  itemProps: {
                    ...getItemProps({ item: omit(item, ['disabled']) }),
                    key,
                  },
                  defaultItemStyles: itemStyles,
                  key,
                  item,
                  getItemProps,
                  index,
                  isHighlighted,
                  disabled: item.disabled,
                  hideCheckbox: item.hideCheckbox,
                  fsItemElement,
                  fsParent,
                  fsType,
                  fsName,
                });
              }
              return (
                <Item
                  className={FS_MASK}
                  key={item.id || itemToString(item)}
                  isHighlighted={isHighlighted}
                  titleAttr={
                    item?.titleAttr ? item?.titleAttr : itemToString(item)
                  }
                  disabled={item.disabled}
                  fsElement={fsItemElement}
                  fsParent={fsParent ?? (item.id || itemToString(item))}
                  fsName={fsName ?? itemToString(item)}
                  fsType={fsType}
                  {...getItemProps({
                    key,
                    index,
                    item: omit(item, ['disabled']),
                    disabled: item.disabled,
                  })}
                >
                  {item.newLabel}
                  {!item.newLabel &&
                    (isFunction(item.label)
                      ? item.label(item.value)
                      : item.label === ''
                      ? '---'
                      : item.label)}
                </Item>
              );
            })}
          </ItemContainer>
        );

        return (
          <div
            css={{ position: 'relative' }}
            data-scope={permissionScope}
            // This attr is really just for Cypress tests.
            // It helps us know that the dropdown items probably won't change (re-render)
            data-has-settled={!isTyping && !isLoading && !loadingTimer}
            ref={parentContainerRef as fixMe}
            data-fieldname={name || inputProps?.name}
            {...restProps}
          >
            {label && <Label {...getLabelProps()}>{label}</Label>}
            <div
              css={{ position: 'relative', height: '100%' }}
              {...getRootProps({ refKey: 'ref' }, { suppressRefError: true })}
            >
              {showSearchIcon && (
                <Icon
                  i="search"
                  color={gray[70]}
                  size="sm"
                  css={{
                    position: 'absolute',
                    top: 11,
                    left: 9,
                    pointerEvents: 'none',
                  }}
                />
              )}
              <Input
                className={FS_MASK}
                {...getInputProps({
                  onKeyDown: (e: KeyboardEvent): void => {
                    if (e.key === 'Tab') {
                      if (!disableSelectOnTab && isOpen) {
                        selectHighlightedItem();
                      } else if (!inputValue) {
                        // workaround for an upstream downshift issue (technically not a bug)
                        // https://github.com/downshift-js/downshift/issues/714
                        // switching to useCombobox should solve this when its released
                        clearSelection();
                      }
                    }
                  },
                })}
                ref={inputRef as fixMe}
                title={
                  itemToString(selectedItem || null) || inputValue || undefined
                }
                autoComplete={AUTOCOMPLETE_OFF_VALUE}
                disabled={restProps.disabled}
                css={
                  {
                    background: inputProps?.readOnly ? gray[95] : '',
                    paddingRight: maxItemSelectLimit ? 30 : 8,
                    ...(showSearchIcon && { paddingLeft: 23 }),
                    ...(inputProps?.css as CSSObject),
                  } as CSSObject
                }
                {...omit(inputProps, ['css'])}
                name={inputProps?.name || name}
                onFocus={async (e): Promise<void> => {
                  if (renderDropdownInPopper) {
                    await popperUpdate?.();
                  }
                  inputProps?.onFocus?.(e);
                  if (openMenuOnFocus) {
                    openMenu();
                  }
                }}
                fsParent={fsParent}
                fsElement={fsSearchElement}
                fsType={fsType}
                fsName={fsName}
              />
              {maxItemSelectLimit && (
                <Tooltip label={`Select up to ${maxItemSelectLimit} items`}>
                  <div
                    data-testid="max-selection-limit"
                    css={{
                      display: 'block',
                      position: 'absolute',
                      userSelect: 'none',
                      top: 9,
                      right: 9,
                      color: gray[70],
                    }}
                  >{`${selectedItemCount}/${maxItemSelectLimit}`}</div>
                </Tooltip>
              )}
            </div>
            {renderDropdownInPopper ? (
              <>
                {createPortal(
                  <div
                    ref={itemContainerRef}
                    style={{
                      ...popperStyles.popper,
                      zIndex: popperZIndex ?? AUTOCOMPLETE,
                    }}
                    {...popperAttributes.popper}
                  >
                    {isOpen && itemContainer}
                  </div>,
                  document.body
                )}
              </>
            ) : (
              isOpen && itemContainer
            )}
          </div>
        );
      }}
    </Downshift>
  );
};
