export enum MinionConfigTypes {
  A1997 = 'a1997',
  Abc = 'abc',
  ABCTesting1234 = 'ABCTesting1234',
  Ac123 = 'ac123',
  AccessorialLineItemApprovalStatus = 'accessorialLineItemApprovalStatus',
  ActivationStatusCancelReason = 'activationStatusCancelReason',
  ActivationStatusHoldReason = 'activationStatusHoldReason',
  ActivationStatusType = 'activationStatusType',
  AddressType = 'addressType',
  AdvanceDenialType = 'advanceDenialType',
  AdvanceType = 'advanceType',
  Anki = 'anki',
  AppointmentConfirmedCheckboxBehavior = 'appointmentConfirmedCheckboxBehavior',
  ArapFirstPickupLocationType = 'arapFirstPickupLocationType',
  ArapLastStopLocationType = 'arapLastStopLocationType',
  ArrivalGeofenceRadius = 'arrivalGeofenceRadius',
  AutoDispatchRouteTypeDray = 'autoDispatchRouteTypeDray',
  AutoDispatchCarrierTrackingEdiOrTms = 'autoDispatchCarrierTrackingEdiOrTms',
  AutoDispatchRouteTypeRail = 'autoDispatchRouteTypeRail',
  AutoDispatchRouteSizeLtl = 'autoDispatchRouteSizeLtl',
  AutomatedLTLRatingRerating = 'automatedLTLRatingRerating',
  AutomatedLTLRatingReratingMastermindQuotes = 'automatedLTLRatingReratingMastermindQuotes',
  AutomatedRatingRerating = 'automatedRatingRerating',
  AutomatedRatingReratingMastermindQuotes = 'automatedRatingReratingMastermindQuotes',
  AutoStartTenderMaxLeadTime = 'autoStartTenderMaxLeadTime',
  AutoStartTenderMinLeadTime = 'autoStartTenderMinLeadTime',
  AutoStartTenderPlan = 'autoStartTenderPlan',
  AvailableRoutesRefreshTimer = 'availableRoutesRefreshTimer',
  BackdatingQuoteThreshold = 'backdatingQuoteThreshold',
  BidLane = 'bidLane',
  BounceReason = 'bounceReason',
  BounceType = 'bounceType',
  BusinessUnit = 'businessUnit',
  BusinessUnitRegionSet = 'businessUnitRegionSet',
  CapacityDisplayStyle = 'capacityDisplayStyle',
  CapacityPhosSource = 'capacityPhosSource',
  CapacityType = 'capacityType',
  CapacityUtilizationStatus = 'capacityUtilizationStatus',
  CanadianAuthorityCodeType = 'canadianAuthorityCodeType',
  CarrierAccountingStatus = 'carrierAccountingStatus',
  CarrierAddressType = 'carrierAddressType',
  CarrierCertificationType = 'carrierCertificationType',
  CarrierContactType = 'carrierContactType',
  CarrierCrmDocumentsDirection = 'carrierCrmDocumentsDirection',
  CarrierCrmDocumentsSource = 'carrierCrmDocumentsSource',
  CarrierCrmDocumentsType = 'carrierCrmDocumentsType',
  CarrierCrmInteractionsCompleteInteractionOutcome = 'carrierCrmInteractionsCompleteInteractionOutcome',
  CarrierCrmInteractionsDirection = 'carrierCrmInteractionsDirection',
  CarrierCrmInteractionsObjective = 'carrierCrmInteractionsObjective',
  CarrierCrmInteractionsOutcome = 'carrierCrmInteractionsOutcome',
  CarrierCrmInteractionsRescheduleReason = 'carrierCrmInteractionsRescheduleReason',
  CarrierCrmInteractionsStatus = 'carrierCrmInteractionsStatus',
  CarrierCrmInteractionsType = 'carrierCrmInteractionsType',
  CarrierCrmInteractionsVia = 'carrierCrmInteractionsVia',
  CarrierCrmNotesType = 'carrierCrmNotesType',
  CarrierCrmOpportunityCloseReason = 'carrierCrmOpportunityCloseReason',
  CarrierCrmOpportunityPricingStrategy = 'carrierCrmOpportunityPricingStrategy',
  CarrierCrmOpportunitySolution = 'carrierCrmOpportunitySolution',
  CarrierCrmOpportunitySolutionType = 'carrierCrmOpportunitySolutionType',
  CarrierCrmOpportunitySource = 'carrierCrmOpportunitySource',
  CarrierCrmOpportunityStage = 'carrierCrmOpportunityStage',
  CarrierCrmOpportunityStatus = 'carrierCrmOpportunityStatus',
  CarrierCrmOpportunityStatusV2 = 'carrierCrmOpportunityStatusV2',
  CarrierCrmOpportunityType = 'carrierCrmOpportunityType',
  CarrierDocumentSourceType = 'carrierDocumentSourceType',
  CarrierDocumentType = 'carrierDocumentType',
  CarrierFacilityRelationshipType = 'carrierFacilityRelationshipType',
  CarrierFacilityRelationshipTypes = 'carrierFacilityRelationshipTypes',
  CarrierIdentifierType = 'carrierIdentifierType',
  CarrierInsuranceType = 'carrierInsuranceType',
  CarrierInteractionFrequency = 'carrierInteractionFrequency',
  CarrierInteractionOutcome = 'carrierInteractionOutcome',
  CarrierInteractions = 'carrierInteractions',
  CarrierInteractionType = 'carrierInteractionType',
  CarrierInteractionVia = 'carrierInteractionVia',
  CarrierLeadSource = 'carrierLeadSource',
  CarrierLevelType = 'carrierLevelType',
  CarrierOpportunityType = 'carrierOpportunityType',
  CarrierPaymentTerm = 'carrierPaymentTerm',
  CarrierRecordOrganization = 'carrierRecordOrganization',
  CarrierRelationshipType = 'carrierRelationshipType',
  CarrierReleaseMethod = 'carrierReleaseMethod',
  CarrierRepType = 'carrierRepType',
  CarrierSalesProgressType = 'carrierSalesProgressType',
  CarrierServiceType = 'carrierServiceType',
  CarrierStandingReasonCode = 'carrierStandingReasonCode',
  CarrierStandingStatusType = 'carrierStandingStatusType',
  CarrierStandingType = 'carrierStandingType',
  CarrierTenderStatus = 'carrierTenderStatus',
  CarrierType = 'carrierType',
  CarrierValidationOverrideReason = 'CarrierValidationOverrideReason',
  CarrierExternalAccess = 'enableCarrierExternalAccess',
  ChargeType = 'chargeType',
  ChargeTypeCategory = 'chargeTypeCategory',
  ChatType = 'chatType',
  Check = 'check',
  CheckChild = 'check child',
  Chhayatest = 'Chhayatest',
  ClientExceptionReasonCodes = 'clientExceptionReasonCodes',
  ClientExceptionsBypassCodes = 'clientExceptionsBypassCodes',
  ClientExceptionsReasonCodes = 'clientExceptionsReasonCodes',
  ClientExceptionsType = 'clientExceptionsType',
  CommissionBasis = 'commissionBasis',
  CommissionMarginBasis = 'commissionMarginBasis',
  CommissionPayTrigger = 'commissionPayTrigger',
  CommissionPlanAppliesTo = 'commissionPlanAppliesTo',
  CommissionType = 'commissionType',
  CommitmentFrequencyType = 'commitmentFrequencyType',
  CommitmentWeekStarting = 'commitmentWeekStarting',
  CommodityDimsDisplayDefaultMultiUnit = 'commodityDimsDisplayDefaultMultiUnit',
  CommodityDimsDisplayOption = 'commodityDimsDisplayOption',
  CommodityFreightClassType = 'commodityFreightClassType',
  CommodityHazmatClassType = 'commodityHazmatClassType',
  CommodityLoadOnType = 'commodityLoadOnType',
  CommodityPackagingType = 'commodityPackagingType',
  CommodityPieceType = 'commodityPieceType',
  CompanyOverheadType = 'companyOverheadType',
  CompensationPeriod = 'compensationPeriod',
  ConfigureInvoiceLanguage = 'configureInvoiceLanguage',
  ContactAssociatedEntitiesExternalSystemUser = 'contactAssociatedEntitiesExternalSystemUser',
  ContactBusinessInfoCommunicationPreference = 'contactBusinessInfoCommunicationPreference',
  ContactBusinessInfoPricingBehavior = 'contactBusinessInfoPricingBehavior',
  ContactBusinessInfoWorkEnvironment = 'contactBusinessInfoWorkEnvironment',
  ContactChatLabel = 'contactChatLabel',
  ContactDepartment = 'contactDepartment',
  ContactEmailLabel = 'contactEmailLabel',
  ContactEntityStatus = 'contactEntityStatus',
  ContactEntityStatusReason = 'contactEntityStatusReason',
  ContactEntityType = 'contactEntityType',
  ContactFunction = 'contactFunction',
  ContactLevel = 'contactLevel',
  ContactNamePronouns = 'contactNamePronouns',
  ContactNameSuffix = 'contactNameSuffix',
  ContactPersonalInfoLanguage = 'contactPersonalInfoLanguage',
  ContactPhoneLabel = 'contactPhoneLabel',
  ContactRepRelationship = 'contactRepRelationship',
  ContactType = 'contactType',
  ContainerProgram = 'containerProgram',
  CostQuote = 'costQuote',
  CostQuoteType = 'costQuoteType',
  Country = 'country',
  CreateLastFreeDayException = 'createLastFreeDayException',
  CreateSource = 'createSource',
  CreditProvider = 'creditProvider',
  CrmCompleteInteractionOutcome = 'crmCompleteInteractionOutcome',
  CrmRescheduleInteractionReason = 'crmRescheduleInteractionReason',
  CrmScheduleInteractionDirection = 'crmScheduleInteractionDirection',
  CrmScheduleInteractionObjective = 'crmScheduleInteractionObjective',
  CrmScheduleInteractionRepsGroups = 'crmScheduleInteractionRepsGroups',
  CrmScheduleInteractionVia = 'crmScheduleInteractionVia',
  Currency = 'currency',
  CustomerAccountingStatus = 'customerAccountingStatus',
  CustomerAddressType = 'customerAddressType',
  CustomerCarrierRelationshipType = 'customerCarrierRelationshipType',
  CustomerContactType = 'customerContactType',
  CustomerCreditStatus = 'customerCreditStatus',
  CustomerCrmDocumentsDirection = 'customerCrmDocumentsDirection',
  CustomerCrmDocumentsSource = 'customerCrmDocumentsSource',
  CustomerCrmDocumentsType = 'customerCrmDocumentsType',
  CustomerCrmInteractionsCompleteInteractionOutcome = 'customerCrmInteractionsCompleteInteractionOutcome',
  CustomerCrmInteractionsDirection = 'customerCrmInteractionsDirection',
  CustomerCrmInteractionsObjective = 'customerCrmInteractionsObjective',
  CustomerCrmInteractionsOutcome = 'customerCrmInteractionsOutcome',
  CustomerCrmInteractionsRescheduleReason = 'customerCrmInteractionsRescheduleReason',
  CustomerCrmInteractionsStatus = 'customerCrmInteractionsStatus',
  CustomerCrmInteractionsType = 'customerCrmInteractionsType',
  CustomerCrmInteractionsVia = 'customerCrmInteractionsVia',
  CustomerCrmNotesType = 'customerCrmNotesType',
  CustomerCrmOpportunityCloseReason = 'customerCrmOpportunityCloseReason',
  CustomerCrmOpportunityPricingStrategy = 'customerCrmOpportunityPricingStrategy',
  CustomerCrmOpportunitySolution = 'customerCrmOpportunitySolution',
  CustomerCrmOpportunitySolutionType = 'customerCrmOpportunitySolutionType',
  CustomerCrmOpportunitySource = 'customerCrmOpportunitySource',
  CustomerCrmOpportunityStage = 'customerCrmOpportunityStage',
  CustomerCrmOpportunityStatus = 'customerCrmOpportunityStatus',
  CustomerCrmOpportunityStatusV2 = 'customerCrmOpportunityStatusV2',
  CustomerCrmOpportunityType = 'customerCrmOpportunityType',
  CustomerDocumentProvider = 'customerDocumentProvider',
  CustomerDocumentType = 'customerDocumentType',
  CustomerGroupType = 'customerGroupType',
  CustomerLeadSource = 'customerLeadSource',
  CustomerLevelType = 'customerLevelType',
  CustomerMarkupType = 'customerMarkupType',
  CustomerOpportunityType = 'customerOpportunityType',
  CustomerOverheadType = 'customerOverheadType',
  CustomerPaymentTerm = 'customerPaymentTerm',
  CustomerRecordOrganization = 'customerRecordOrganization',
  CustomerRelationshipType = 'customerRelationshipType',
  CustomerRepType = 'customerRepType',
  CustomerSalesProgressType = 'customerSalesProgressType',
  CustomerStatusType = 'customerStatusType',
  CustomerType = 'customerType',
  DataDictionaryRequestCategory = 'dataDictionaryRequestCategory',
  DataDictionaryRequestedRelease = 'dataDictionaryRequestedRelease',
  DataDictionaryRequestStatus = 'dataDictionaryRequestStatus',
  DataDictionaryRequestSubCategory = 'dataDictionaryRequestSubCategory',
  DataDictionaryRequestTermType = 'dataDictionaryRequestTermType',
  DeclinedReason = 'declinedReason',
  DemoTest25 = 'demoTest25',
  DepartureGeofenceRadius = 'departureGeofenceRadius',
  DestinationSource = 'destinationSource',
  DisableStopEventsBeforeStatus = 'disableStopEventsBeforeStatus',
  DisplayFacilityName = 'displayFacilityName',
  RateConDisableEDIDocuments = 'disableDocumentsForEdiRateCons',
  DistanceProvider = 'distanceProvider',
  DistanceSearchPreference = 'distanceSearchPreference',
  Division = 'division',
  DivisionBasedMatchingAndRoutes = 'divisionBasedMatchingAndRoutes',
  DNUDriverPreferenceCareerGoalReason = 'DNU-driverPreferenceCareerGoalReason',
  DocketNumberRequiredInboundTruckerToolsPayload = 'docketNumberRequiredInboundTruckerToolsPayload',
  DomainOwnership = 'domainOwnership',
  DriverAccountingStatus = 'driverAccountingStatus',
  DriverBounceReason = 'driverBounceReason',
  DriverDispatchTo = 'driverDispatchTo',
  DriverDocumentDepartmentType = 'driverDocumentDepartmentType',
  DriverDocumentSourceType = 'driverDocumentSourceType',
  DriverDocumentType = 'driverDocumentType',
  DriverEldNotRequired = 'driverEldNotRequired',
  DriverElectronicTrackingType = 'driverElectronicTrackingType',
  DriverFleetRelationshipType = 'driverFleetRelationshipType',
  DriverGeneralAddressType = 'driverGeneralAddressType',
  DriverGeneralAwardsType = 'driverGeneralAwardsType',
  DriverGeneralCertificationType = 'driverGeneralCertificationType',
  DriverGeneralContactsType = 'driverGeneralContactsType',
  DriverGeneralEquipmentCode = 'driverGeneralEquipmentCode',
  DriverGeneralEquipmentCondition = 'driverGeneralEquipmentCondition',
  DriverGeneralEquipmentType = 'driverGeneralEquipmentType',
  DriverGeneralIdentifersType = 'driverGeneralIdentifersType',
  DriverGeneralOpDetailsHOS = 'driverGeneralOpDetailsHOS',
  DriverGeneralOpDetailsPowerTractorType = 'driverGeneralOpDetailsPowerTractorType',
  DriverGeneralOpDetailsStatus = 'driverGeneralOpDetailsStatus',
  DriverGeneralOpDetailsTrailerType = 'driverGeneralOpDetailsTrailerType',
  DriverGeneralOrgDivision = 'driverGeneralOrgDivision',
  DriverGeneralOrgFleet = 'driverGeneralOrgFleet',
  DriverGeneralOrgProgram = 'driverGeneralOrgProgram',
  DriverGeneralOrgProject = 'driverGeneralOrgProject',
  DriverGeneralOrgSegment = 'driverGeneralOrgSegment',
  DriverGeneralPersonalChildren = 'driverGeneralPersonalChildren',
  DriverGeneralPersonalGenderPronoun = 'driverGeneralPersonalGenderPronoun',
  DriverGeneralPersonalMaritalStatus = 'driverGeneralPersonalMaritalStatus',
  DriverGeneralPersonalMilitaryReserveObligation = 'driverGeneralPersonalMilitaryReserveObligation',
  DriverGeneralPersonalParkingSpace = 'driverGeneralPersonalParkingSpace',
  DriverGeneralProfessionalClass = 'driverGeneralProfessionalClass',
  DriverGeneralProfessionalType = 'driverGeneralProfessionalType',
  DriverGeneralSuffix = 'driverGeneralSuffix',
  DriverGeneralTimeOff = 'driverGeneralTimeOff',
  DriverHistoryTrainingOutcome = 'driverHistoryTrainingOutcome',
  DriverHistoryTrainingType = 'driverHistoryTrainingType',
  DriverOpDetailsTrailerGeneralTrailerType = 'driverOpDetailsTrailerGeneralTrailerType',
  DriverPayAttributes = 'driverPayAttributes',
  DriverPayCurrency = 'driverPayCurrency',
  DriverPreferenceCareerGoalReason = 'driverPreferenceCareerGoalReason',
  DriverPreferenceCommodity = 'driverPreferenceCommodity',
  DriverPreferenceCommodityReason = 'driverPreferenceCommodityReason',
  DriverPreferenceCustomerPreferenceReason = 'driverPreferenceCustomerPreferenceReason',
  DriverPreferenceGeoPreferenceDirection = 'driverPreferenceGeoPreferenceDirection',
  DriverPreferenceGeoPreferenceReason = 'driverPreferenceGeoPreferenceReason',
  DriverPreferenceLanesReason = 'DriverPreferenceLanesReason',
  DriverPreferenceOperationalType = 'driverPreferenceOperationalType',
  DriverPreferencePosition = 'driverPreferencePosition',
  DriverPreferencePreference = 'driverPreferencePreference',
  DriverPreferencePreferenceReason = 'driverPreferencePreferenceReason',
  DriverPreferenceRoutesReason = 'DriverPreferenceRoutesReason',
  DriverPreferenceTeamType = 'driverPreferenceTeamType',
  DriverReloadIntent = 'driverReloadIntent',
  DriverRepType = 'driverRepType',
  DriverTargetTimes = 'driverTargetTimes',
  DummyTest = 'dummyTest',
  DriverValidationOverrideReason = 'driverValidationOverrideReason',
  DISTimezone = 'hourlyPayrollStartDayTimezone',
  DaysInServiceReasonCodes = 'daysInServiceReasonCode',
  ETAValidationOverrideReason = 'overrideReason',
  EdiSendOrderDistOnOB214 = 'Edi Send Order Dist On OB214',
  EdiCarrierOutboundIsaId = 'ediCarrierOutboundIsaId',
  EdiCustomerOutboundControlList = 'ediCustomerOutboundControlList',
  EdiDefault214DriverLocationTimeSchedule = 'ediDefault214DriverLocationTimeSchedule',
  EdiErrorVisibilityAvailableTransactions = 'ediErrorVisibilityAvailableTransactions',
  Ediob404DefaultcontactFunctionCode = 'ediob404defaultcontactFunctionCode',
  ediob404DefaultpartyIdentificatioName = 'ediob404defaultpartyIdentificatioName',
  Ediob404DefaultpostalCode = 'ediob404defaultpostalCode',
  Ediob404DefaultstateOrProvinceCode = 'ediob404defaultstateOrProvinceCode',
  Ediob404DefaultaddressInformation = 'ediob404defaultaddressInformation',
  Ediob404DefaultcityName = 'ediob404defaultcityName',
  Ediob404DefaultcountryCode = 'ediob404defaultcountryCode',
  Ediob404NpdefaultaddressInformation = 'ediob404npdefaultaddressInformation',
  Ediob404NpdefaultcityName = 'ediob404npdefaultcityName',
  Ediob404NpdefaultcommunicationNumber = 'ediob404npdefaultcommunicationNumber',
  Ediob404NpdefaultcontactFunctionCode = 'ediob404npdefaultcontactFunctionCode',
  Ediob404NpdefaultcountryCode = 'ediob404npdefaultcountryCode',
  Ediob404NpdefaultentityIdentifierCode = 'ediob404npdefaultentityIdentifierCode',
  Ediob404Npdefaultn1Name = 'ediob404npdefaultn1Name',
  Ediob404NpdefaultpartyIdentificationName = 'ediob404npdefaultpartyIdentificationName',
  Ediob404NpdefaultpostalCode = 'ediob404npdefaultpostalCode',
  Ediob404NpdefaultstateOrProvinceCode = 'ediob404npdefaultstateOrProvinceCode',
  ElectronicTrackingMethod = 'electronicTrackingMethod',
  ElectronicTrackingType = 'electronicTrackingType',
  EmployeeCompanyLevel = 'employeeCompanyLevel',
  EmployeeDepartment = 'employeeDepartment',
  EmployeeDepartmentLevel = 'employeeDepartmentLevel',
  EmployeeDivision = 'employeeDivision',
  EmployeeGender = 'employeeGender',
  EmployeeGroup = 'employeeGroup',
  EmployeeOffice = 'employeeOffice',
  EmployeeRole = 'employeeRole',
  EmployeeShirtSize = 'employeeShirtSize',
  EmployeeStatus = 'employeeStatus',
  EmployeeSuffix = 'employeeSuffix',
  EmployeeTime = 'employeeTime',
  EmployeeType = 'employeeType',
  EmployeeProvider = 'employeeProvider',
  EnableAssignDriverButtonInModal = 'enableAssignDriverButtonInModal',
  EnableBookingOrchestrationAutoBounce = 'enableBookingOrchestrationAutoBounce',
  EnableBouncedCarrierValidation = 'enableBouncedCarrierValidation',
  EnableCanvasBusinessUnitAsDdt = 'enableCanvasBusinessUnitAsDdt',
  EnableCanvasImdlRouteModeAndRouteType = 'enableCanvasImdlRouteModeAndRouteType',
  EnableCarrierTradingPartnerEdiHeaders = 'enableCarrierTradingPartnerEdiHeaders',
  EnableDriverTendering = 'driverTendering',
  EnableDuplicateInvoiceValidation = 'enableDuplicateInvoiceValidation',
  EnableGeofencingArrivals = 'enableGeofencingArrivals',
  EnableGeofencingDepartures = 'enableGeofencingDepartures',
  EnableInactiveRouteValidation = 'enableInactiveRouteValidation',
  EnableIntelligentMatchingP44 = 'enableIntelligentMatchingP44',
  EnableInTRUERouteValidation = 'enableInTRUERouteValidation',
  EnableInvalidCurrencyValidation = 'enableInvalidCurrencyValidation',
  EnableInvalidRouteValidation = 'enableInvalidRouteValidation',
  EnableMainPageOffersTab = 'enableMainPageOffersTab',
  EnableMissingVendorInvoiceException = 'enableMissingVendorInvoiceException',
  EnablePrePlans = 'enablePrePlans',
  EnableRemitToAddressValidation = 'enableRemitToAddressValidation',
  EnableThirdPartyDocMapping = 'enableThirdPartyDocMapping',
  EnableTradingPartnerEDIHeaders = 'enableTradingPartnerEDIHeaders',
  EnableVendorInvoiceToleranceException = 'enableVendorInvoiceToleranceException',
  EntityCrmSearchRepsOrGroups = 'entityCrmSearchRepsOrGroups',
  EnvPRomoTestingDDT = 'Env PRomo Testing DDT',
  EnvironmentPromotionDemo = 'environmentPromotionDemo',
  ExceptionReasonCodes = 'exceptionReasonCodes',
  ExceptionSubCategory = 'exceptionSubCategory',
  ExceptionType = 'exceptionType',
  ExcludeFromFreitRaterDistance = 'excludeFromFreitRaterDistance',
  ExtractFileTypes = 'extractFileTypes',
  FacilityAccommodationsRating = 'facilityAccommodationsRating',
  FacilityActivitiesVariability = 'facilityActivitiesVariability',
  FacilityAddressType = 'facilityAddressType',
  FacilityContactType = 'facilityContactType',
  FacilityDocumentSourceType = 'facilityDocumentSourceType',
  FacilityDocumentType = 'facilityDocumentType',
  FacilityGatesDocumentationNeeded = 'facilityGatesDocumentationNeeded',
  FacilityGatesGuardType = 'facilityGatesGuardType',
  FacilityGatesIdChecked = 'facilityGatesIdChecked',
  FacilityHolidays = 'facilityHolidays',
  FacilityHolidaysOpenClosed = 'facilityHolidaysOpenClosed',
  FacilityIdentifierType = 'facilityIdentifierType',
  FacilityRelationshipType = 'facilityRelationshipType',
  FacilityRepType = 'facilityRepType',
  FacilitySchedulingInOut = 'facilitySchedulingInOut',
  FacilitySchedulingType = 'facilitySchedulingType',
  FacilitySchedulingWork = 'facilitySchedulingWork',
  FacilityStatus = 'facilityStatus',
  FacilitySublocationGuarded = 'facilitySublocationGuarded',
  FacilitySublocationLocked = 'facilitySublocationLocked',
  FacilitySublocationType = 'facilitySublocationType',
  FacilityType = 'facilityType',
  FeatureFlagTesting = 'FeatureFlag Testing',
  FeatureOb204MergeRouteAndOrderReferences = 'featureOb204MergeRouteAndOrderReferences',
  FinalizedFuelStatus = 'finalizedFuelStatus',
  FuelApplicationMethod = 'fuelApplicationMethod',
  FuelIndices = 'fuelIndices',
  FuelReferenceType = 'fuelReferenceType',
  FuelScale = 'fuelScale',
  FuelSurchargeDate = 'fuelSurchargeDate',
  FuelSurchargeFrequency = 'fuelSurchargeFrequency',
  FuelWeekday = 'fuelWeekday',
  GeneralinformationMeasurementType = 'generalinformationMeasurementType',
  GeographyType = 'geographyType',
  GLSegmentationConfigurationLevel = 'GLSegmentationConfigurationLevel',
  GLSegmentationConfigurationType = 'GLSegmentationConfigurationType',
  GLSegmentSeparator = 'GLSegmentSeparator',
  GroupPurpose = 'groupPurpose',
  GroupType = 'groupType',
  GroupStatus = 'groupStatus',
  GroupCategory = 'groupCategory',
  DriverGroupAttributes = 'driverGroupAttributes',
  CarrierGroupAttributes = 'carrierGroupAttributes',
  HaykAccessorialAutomationDriverAssist = 'haykAccessorialAutomationDriverAssist',
  HaykAccessorialAutomationHazmat = 'haykAccessorialAutomationHazmat',
  HaykAccessorialAutomationLiftGate = 'haykAccessorialAutomationLiftGate',
  HaykAccessorialAutomationStopOffCharge = 'haykAccessorialAutomationStopOffCharge',
  HaykAccessorialAutomationTONU = 'haykAccessorialAutomationTONU',
  Hjl = 'hjl',
  HideResourceTabTrackingFieldsForFleetRoutes = 'hideResourceTabTrackingFieldsForFleetRoutes',
  IdentifierType = 'identifierType',
  ImperialDensityUOM = 'imperialDensityUOM',
  ImperialDimensionsUOM = 'imperialDimensionsUOM',
  ImperialLinearUOM = 'imperialLinearUOM',
  ImperialTemperatureUOM = 'imperialTemperatureUOM',
  ImperialUOMOdometer = 'imperialUOMOdometer',
  ImperialUOMTankCapacity = 'imperialUOMTankCapacity',
  ImperialVolumeUOM = 'imperialVolumeUOM',
  ImperialWeightUOM = 'imperialWeightUOM',
  INACTIVECommissionPayTrigger = 'INACTIVE_commissionPayTrigger',
  IncidentFaultParties = 'incidentFaultParties',
  IncidentGravities = 'incidentGravities',
  IncidentGravity = 'incidentGravity',
  IncidentTypes = 'incidentTypes',
  IncludeAssociatedRepsOnTenderEmail = 'includeAssociatedRepsOnTenderEmail',
  IncludeMainLoadRepsOnTenderEmail = 'includeMainLoadRepsOnTenderEmail',
  InsuranceAMBestRating = 'insuranceAMBestRating',
  InsuranceBufferDays = 'insuranceBufferDays',
  InteractionFrequency = 'interactionFrequency',
  InteractionOutcome = 'interactionOutcome',
  InteractionType = 'interactionType',
  InteractionVia = 'interactionVia',
  InvoiceDistributionFrequency = 'invoiceDistributionFrequency',
  InvoiceDistributionMethod = 'invoiceDistributionMethod',
  InvoiceGrouping = 'invoiceGrouping',
  InvoiceGroupingMethod = 'invoiceGroupingMethod',
  InvoiceType = 'invoiceType',
  InvoiceHoldEvents = 'invoiceHoldEvents',
  InvoiceTemplate = 'invoiceTemplate',
  Isha1 = 'isha1',
  IsLoadDriversTabOwnerFieldShown = 'isLoadDriversTabOwnerFieldShown',
  LaneType = 'laneType',
  LateArrivalReasonCode = 'lateArrivalReasonCode',
  LateArrivalWindow = 'lateArrivalWindow',
  ListCheck = 'listCheck',
  LiveDropValue = 'liveDropValue',
  LoadBracing = 'loadBracing',
  LoadPallets = 'loadPallets',
  LoadDirectionType = 'loadDirectionType',
  LoadDocumentSource = 'loadDocumentSource',
  LoadDocumentType = 'loadDocumentType',
  LoadReferenceType = 'loadReferenceType',
  LoadSize = 'loadSize',
  LoadStopType = 'loadStopType',
  LoadTarps = 'loadTarps',
  LoadTrackingActionType = 'loadTrackingActionType',
  LoadTrackingDelayReason = 'loadTrackingDelayReason',
  LoadTrackingInformationFrom = 'loadTrackingInformationFrom',
  LocationUpdateSource = 'locationUpdateSource',
  LocationUpdatesSendingRestriction = 'ediOb_214SendLocationUpdates',
  LockDivisionRouteVisibility = 'lockDivisionRouteVisibility',
  LockPhoneFieldByDefault = 'lockPhoneFieldByDefault',
  LseDoNotUseCurrentStopFromGraphql = 'lseDoNotUseCurrentStopFromGraphql',
  LseDriverAssignedDispatchedNotRequired = 'lseDriverAssignedDispatchedNotRequired',
  LSEPopulateRouteTerminalStop = 'LSEPopulateRouteTerminalStop',
  LTLGlobalMarkup = 'LTL Global Markup',
  LtlCarrierTenderingPreference = 'ltlCarrierTenderingPreference',
  LtlInterliningType = 'ltlInterliningType',
  ManualTrackingType = 'manualTrackingType',
  MarginBasis = 'marginBasis',
  MasterBid = 'masterBid',
  MasterSignExpiration = 'masterSignExpiration',
  MasterSignRateConfirmation = 'masterSignRateConfirmation',
  MaximumContainerMatchingDeadhead = 'maximumContainerMatchingDeadhead',
  MaximumHoursToAutoPostBeforePickup = 'maximumHoursToAutoPostBeforePickup',
  MaximumRoutesOnLoad = 'maximumRoutesOnLoad',
  MaxNumberOfTendersForARoute = 'maxNumberOfTendersForARoute',
  MaxNumberOfTendersForDriverCapacity = 'maxNumberOfTendersForDriverCapacity',
  MBTestTerm = 'MB Test Term',
  MessagingService = 'messagingService',
  MetricDensityUOM = 'metricDensityUOM',
  MetricDimensionsUOM = 'metricDimensionsUOM',
  MetricLinearUOM = 'metricLinearUOM',
  MetricTemperatureUOM = 'metricTemperatureUOM',
  MetricUOMOdometer = 'metricUOMOdometer',
  MetricUOMTankCapacity = 'metricUOMTankCapacity',
  MetricVolumeUOM = 'metricVolumeUOM',
  MetricWeightUOM = 'metricWeightUOM',
  MileageAzureRouteType = 'mileageAzureRouteType',
  MileageAzureVehicleLoadType = 'mileageAzureVehicleLoadType',
  MileageMileMakerAvoidPreferences = 'mileageMileMakerAvoidPreferences',
  MileageMileMakerGuideVersion = 'mileageMileMakerGuideVersion',
  MileageMileMakerHazmatType = 'mileageMileMakerHazmatType',
  MileageMileMakerRouteType = 'mileageMileMakerRouteType',
  MileageMileMakerRoutingType = 'mileageMileMakerRoutingType',
  MileageMileMakerVehicleParameters = 'mileageMileMakerVehicleParameters',
  MileagePCMilerClassOverrides = 'mileagePCMilerClassOverrides',
  MileagePCMilerDataVersions = 'mileagePCMilerDataVersions',
  MileagePCMilerHazMatType = 'mileagePCMilerHazMatType',
  MileagePCMilerRoutingType = 'mileagePcMilerRoutingType',
  MileagePCMilerTollRoads = 'mileagePCMilerTollRoads',
  MileagePCMilerTollRoadsLegacy = 'mileagePCMilerTollRoadsLegacy',
  MinionDisplayNameTest = 'minionDisplayNameTest',
  MinionEnvironmentPromotion = 'minionEnvironmentPromotion',
  MinionTest = 'minionTest',
  MinionTest12 = 'minionTest12',
  MmLoca = 'mmLoca',
  MultiRouteAutoStartTenderPlan = 'multiRouteAutoStartTenderPlan',
  MyGv = 'myGv',
  NavigationType = 'navigationType',
  NewTestRequest6 = 'newTestRequest6',
  NewTestRequest7 = 'newTestRequest7',
  NextDayDriverCapacityCreation = 'nextDayDriverCapacityCreation',
  NoteType = 'noteType',
  OfferReason = 'offerReason',
  OfferSource = 'offerSource',
  OfferType = 'offerType',
  OfferPage = 'offerPage',
  OORTHRESHOLD = 'OOR_THRESHOLD',
  OrderCostAllocationMethod = 'orderCostAllocationMethod',
  OrderDivisionType = 'orderDivisionType',
  OrderFinancialStatusExceptionReasons = 'orderFinancialStatusExceptionReasons',
  OrderFinancialStatusReasons = 'orderFinancialStatusReasons',
  OrderFormType = 'orderFormType',
  OrderFinancialStatusType = 'orderFinancialStatusType',
  OrderGLSegmentationType = 'OrderGLSegmentationType',
  OrderPaymentTerm = 'orderPaymentTerm',
  OrderRelationType = 'orderRelationType',
  OrderRequirement = 'orderRequirement',
  OrderStopDesignation = 'orderStopDesignation',
  OrderType = 'orderType',
  OutOfRouteThresholdAcquisition = 'outOfRouteThresholdAcquisition',
  OutOfRouteThresholdDisposition = 'outOfRouteThresholdDisposition',
  PatternsCrossingType = 'patternsCrossingType',
  PaymentMethod = 'paymentMethod',
  PayTrigger = 'payTrigger',
  PCMilerDistanceDetailsParameters = 'pcMilerDistanceDetailsParameters',
  PerUnit = 'perUnit',
  PersonalInformationType = 'personalInformationType',
  PoolType = 'poolType',
  PoolDirection = 'poolDirection',
  PortfolioROEStatus = 'portfolioROEStatus',
  PostingsAutoPostToDAT = 'postingsAutoPostToDAT',
  PostingsAutoPostToTruckerTools = 'postingsAutoPostToTruckerTools',
  PostingsAutoPostToTruckstop = 'postingsAutoPostToTruckstop',
  PostingsAutoPostWhenLoadOrderIsFull = 'postingsAutoPostWhenLoadOrderIsFull',
  PostingsAutoPostWhenManuallyRemoved = 'postingsAutoPostWhenManuallyRemoved',
  PostingsAutoPostWhenRouteActivationStatusIsActive = 'postingsAutoPostWhenRouteActivationStatusIsActive',
  PostingsAutoPostWhenRouteLifeCycleStatusIsCovered = 'postingsAutoPostWhenRouteLifeCycleStatusIsCovered',
  PostingsAutoPostWhenRouteLifeCycleStatusIsOpen = 'postingsAutoPostWhenRouteLifeCycleStatusIsOpen',
  PostingsAutoPostWhenRouteStopAppointmentIsSet = 'postingsAutoPostWhenRouteStopAppointmentIsSet',
  PostToDat = 'postToDat',
  PostToTruckerTools = 'postToTruckerTools',
  PostToTruckstop = 'postToTruckstop',
  PowerCARBCompliant = 'powerCARBCompliant',
  PowerDocumentDepartmentType = 'powerDocumentDepartmentType',
  PowerDocumentSourceType = 'powerDocumentSourceType',
  PowerDocumentType = 'powerDocumentType',
  PowerEngineBrake = 'powerEngineBrake',
  PowerEngineMake = 'powerEngineMake',
  PowerEngineModel = 'powerEngineModel',
  PowerFifthWheel = 'powerFifthWheel',
  PowerEquipmentType = 'powerEquipmentType',
  PowerGeneralAppearanceAxleConfig = 'powerGeneralAppearanceAxleConfig',
  PowerGeneralAppearanceSleeperSize = 'powerGeneralAppearanceSleeperSize',
  PowerGeneralAppearanceSleeperType = 'powerGeneralAppearanceSleeperType',
  PowerGeneralAppearanceSuspension = 'powerGeneralAppearanceSuspension',
  PowerGeneralEquipmentCode = 'powerGeneralEquipmentCode',
  PowerGeneralEquipmentCondition = 'powerGeneralEquipmentCondition',
  PowerGeneralInformationMake = 'powerGeneralInformationMake',
  PowerGeneralInformationModel = 'powerGeneralInformationModel',
  PowerGeneralInformationType = 'powerGeneralInformationType',
  PowerGeneralOpsClass = 'powerGeneralOpsClass',
  PowerGeneralOpsFleet = 'powerGeneralOpsFleet',
  PowerGeneralOpsLegalToRun = 'powerGeneralOpsLegalToRun',
  PowerGeneralOpsOwner = 'powerGeneralOpsOwner',
  PowerGeneralOpsSegment = 'powerGeneralOpsSegment',
  PowerGeneralTrackingDevice = 'powerGeneralTrackingDevice',
  VendorRouteFinancialStatusRequirements = 'vendorRouteFinancialStatusRequirements',
  PowerGeneralTrackingModel = 'powerGeneralTrackingModel',
  PowerIdentifierType = 'powerIdentifierType',
  PowerMaintenanceSeverity = 'powerMaintenanceSeverity',
  PowerMaintenanceType = 'powerMaintenanceType',
  PowerOperatingStatus = 'powerOperatingStatus',
  PowerServiceStatus = 'powerServiceStatus',
  PowerTransMake = 'powerTransMake',
  PowerTransModel = 'powerTransModel',
  Project = 'project',
  QuotePriceTier = 'quotePriceTier',
  QuoteTenderType = 'quoteTenderType',
  QuoteType = 'quoteType',
  RailBillingServiceCode = 'railBillingServiceCode',
  RateConContactEmailFieldReadOnly = 'rateConContactEmailReadOnly',
  RateQuote = 'rateQuote',
  RateType = 'rateType',
  RateUnitOfMeasure = 'rateUnitOfMeasure',
  RatingSource = 'ratingSource',
  RegionType = 'regionType',
  RequireAllArrivalAndDepartureDatetimesForDeliveredComplete = 'Require all arrival and departure datetimes for delivered / complete',
  RequireAllArrivalDepartureDateTimesForDeliveredCompleteDONOTUSEBadID = 'requireAllArrivalDepartureDateTimesForDeliveredComplete (DO NOT USE - bad ID)',
  RequireEnvironmentPromotion = 'requireEnvironmentPromotion',
  ReservationType = 'reservationType',
  RestrictEditingOfArrivalDepartureDateTimes = 'restrictEditingOfArrivalDepartureDateTimes',
  RouteActivationStatus = 'routeActivationStatus',
  RouteBookingSourceType = 'routeBookingSourceType',
  RouteFinancialStatusType = 'routeFinancialStatusType',
  RouteGLSegmentationType = 'RouteGLSegmentationType',
  RouteLockTimer = 'routeLockTimer',
  RoutePostingsForDat = 'routePostingsForDat',
  RoutePostingsForTruckerTools = 'routePostingsForTruckerTools',
  RoutePostingsForTruckstop = 'routePostingsForTruckstop',
  RouteRateAllocationMethod = 'routeRateAllocationMethod',
  RouteTransportMode = 'routeTransportMode',
  RouteType = 'routeType',
  RouteVendorReferenceType = 'routeVendorReferenceType',
  RouteVendorType = 'routeVendorType',
  RoutingGuide = 'routingGuide',
  RoutingGuideType = 'routingGuideType',
  RouteLifecycleStatus = 'routeLifecycleStatus',
  RouteDetailsCargoInsurance = 'routeDetailsCargoInsurance',
  RouteDetailsMode = 'routeDetailsMode',
  RouteDetailsPrimaryDivision = 'routeDetailsPrimaryDivision',
  RouteDetailsRouteType = 'routeDetailsRouteType',
  RouteDetailsSize = 'routeDetailsSize',
  RouteDetailsTrailerMinH = 'routeDetailsTrailerMinH',
  RouteDetailsTrailerMinL = 'routeDetailsTrailerMinL',
  RouteDetailsTrailerMinW = 'routeDetailsTrailerMinW',
  RouteDetailsTrailerType = 'routeDetailsTrailerType',
  SchedulingSystemType = 'schedulingSystemType',
  SendAppointmentDetails = 'sendAppointmentDetails',
  Service = 'service',
  ServiceLevel = 'serviceLevel',
  ServiceProfile = 'serviceProfile',
  ServiceProfileDay = 'serviceProfileDay',
  ServiceProfileStatus = 'serviceProfileStatus',
  ServiceProfileType = 'serviceProfileType',
  SfUatLoadFrequency = 'sfUatLoadFrequency',
  SourceType = 'sourceType',
  StopEventsManualEtaDisabled = 'stopEventsManualEtaDisabled',
  StopEventsTrailerValidation = 'stopEventsTrailerValidation',
  StopEventSubType = 'stopEventSubType',
  StopEventType = 'stopEventType',
  StopEventsLastUpdatedSource = 'stopEventsLastUpdatedSource',
  StopEventsTrailerFieldEditable = 'stopEventsTrailerFieldEditable',
  StopRequirement = 'stopRequirement',
  StopScheduleChangeReason = 'stopScheduleChangeReason',
  StoreTenderOffersInDocuments = 'storeTenderOffersInDocuments',
  TariffName = 'tariffName',
  TaskStatuses = 'taskStatuses',
  Temperature = 'Temperature',
  TemperatureUnits = 'temperatureUnits',
  TenantScac = 'tenantScac',
  TenderAppointmentStatusRequired = 'tenderAppointmentStatusRequired',
  TenderMode = 'tenderMode',
  TenderPlanStatus = 'tenderPlanStatus',
  TenderStatusType = 'tenderStatusType',
  Test44 = 'Test 44',
  TestTermStatus = 'test term status',
  TestTest = 'Test Test',
  TestTestV2 = 'Test Test V2',
  TestDdt = 'testDdt',
  TestDdtDemo = 'testDdtDemo',
  TestFeatureFlag = 'testFeatureFlag',
  TestFf = 'testFf',
  TestFlag = 'testFlag',
  TestFlagfeature = 'testFlagfeature',
  Testing = 'Testing',
  TestingDDT2 = 'testing DDT2',
  Testing1234 = 'Testing1234',
  TestingData = 'testingData',
  TestingDdt = 'testingDdt',
  TestOne = 'testOne',
  TestTermDemo = 'testTermDemo',
  TestTest1 = 'testTest1',
  TextGlobalVariableTest = 'textGlobalVariableTest',
  TimelineEventEntry = 'timelineEventEntry',
  TimelineEventType = 'timelineEventType',
  TimelineJournalEntryType = 'timelineJournalEntryType',
  TrackCommitmentPerformanceBasedOn = 'trackCommitmentPerformanceBasedOn',
  Tracking = 'tracking',
  TrackingAutoEtaDataScience = 'trackingAutoEtaDataScience',
  TrackingAutoEtaEnabled = 'trackingAutoEtaEnabled',
  TrackingAutoEtaExternalSourceOverride = 'trackingAutoEtaExternalSourceOverride',
  TrackingAutoEtaNotificationEmail = 'trackingAutoEtaNotificationEmail',
  TrackingAutoEtaSource = 'trackingAutoEtaSource',
  TrackingEtaAllowManualEtaInput = 'trackingEtaAllowManualEtaInput',
  TrackingEtaEnabled = 'trackingEtaEnabled',
  TrackingEtaMaxDrivingSpeed = 'trackingEtaMaxDrivingSpeed',
  TrackingEtaMaxDrivingSpeedtestingGV = 'trackingEtaMaxDrivingSpeedtestingGV',
  TrackingEtaShouldBeCalculatedInMasterMind = 'trackingEtaShouldBeCalculatedInMasterMind',
  TrackingHierarchyEnabled = 'trackingHierarchyEnabled',
  TrackingHierarchyExpectedBufferMinutes = 'trackingHierarchyExpectedBufferMinutes',
  TrackingHierarchyFirstTrackingUpdateThresholdMinutes = 'trackingHierarchyFirstTrackingUpdateThresholdMinutes',
  TrackingHierarchyModeTypeThresholdMinutes = 'trackingHierarchyModeTypeThresholdMinutes',
  TrackingHierarchyNextTrackingMethodThresholdMinutes = 'trackingHierarchyNextTrackingMethodThresholdMinutes',
  TrackingHierarchyPreferredTrackingMethod = 'trackingHierarchyPreferredTrackingMethod',
  TrackingHierarchyThresholdEnabled = 'trackingHierarchyThresholdEnabled',
  TrackingHierarchyWaterfallThresholdMinutes = 'trackingHierarchyWaterfallThresholdMinutes',
  TrackingStatus = 'trackingStatus',
  TrackingStopType = 'trackingStopType',
  TrackingSystem = 'trackingSystem',
  TrackingUpdateSources = 'trackingUpdateSources',
  TrailerAxleType = 'trailerAxleType',
  TrailerCARBCompliant = 'trailerCARBCompliant',
  TrailerCarrierRelationshipType = 'trailerCarrierRelationshipType',
  TrailerCustomerRelationshipType = 'trailerCustomerRelationshipType',
  TrailerDocumentDepartmentType = 'trailerDocumentDepartmentType',
  TrailerDocumentSourceType = 'trailerDocumentSourceType',
  TrailerDocumentType = 'trailerDocumentType',
  TrailerETracks = 'trailerETracks',
  TrailerGeneralAppearanceDoors = 'trailerGeneralAppearanceDoors',
  TrailerGeneralAppearanceFloor = 'trailerGeneralAppearanceFloor',
  TrailerGeneralAppearanceRoof = 'trailerGeneralAppearanceRoof',
  TrailerGeneralAppearanceSkin = 'trailerGeneralAppearanceSkin',
  TrailerGeneralEquipmentCondition = 'trailerGeneralEquipmentCondition',
  TrailerGeneralInformationAuthorizedCountries = 'trailerGeneralInformationAuthorizedCountries',
  TrailerGeneralInformationMake = 'trailerGeneralInformationMake',
  TrailerGeneralInformationModel = 'trailerGeneralInformationModel',
  TrailerGeneralInformationTempMonitoringType = 'trailerGeneralInformationTempMonitoringType',
  TrailerGeneralInformationType = 'trailerGeneralInformationType',
  TrailerGeneralOperationsOwner = 'trailerGeneralOperationsOwner',
  TrailerGeneralTrackingDevice = 'trailerGeneralTrackingDevice',
  TrailerGeneralTrackingModel = 'trailerGeneralTrackingModel',
  TrailerGrouping = 'trailerGrouping',
  TrailerIdentifierType = 'trailerIdentifierType',
  TrailerLandingGear = 'trailerLandingGear',
  TrailerLiftgateType = 'trailerLiftgateType',
  TrailerMaintenanceSeverity = 'trailerMaintenanceSeverity',
  TrailerMaintenanceType = 'trailerMaintenanceType',
  TrailerOperatingStatus = 'trailerOperatingStatus',
  TrailerRemoteAccess = 'trailerRemoteAccess',
  TrailerServiceStatus = 'trailerServiceStatus',
  TrailerServiceStatusReasonCode = 'trailerServiceStatusReasonCode',
  TrailerSuspension = 'trailerSuspension',
  TrailerType = 'trailerType',
  TrailerWorkPerformed = 'trailerWorkPerformed',
  TrailerPrefix = 'trailerPrefix',
  TransportMode = 'transportMode',
  TreatGlobalLTLMarkupAsFlatRate = 'Treat Global LTL Markup as Flat Rate',
  TruckBestMatchDDH = 'truckBestMatchDDH',
  TruckBestMatchODH = 'truckBestMatchODH',
  TruckBestMatchRouteDistance = 'truckBestMatchRouteDistance',
  UnitOfTime = 'unitOfTime',
  UseCanvasBookingRuleSettings = 'useCanvasBookingRuleSettings',
  UsedOn = 'usedOn',
  UseInternalGeoLocation = 'useInternalGeoLocation',
  UseOutbound404Cancellation = 'useOutbound404Cancellation',
  UseOutbound998SetCancellation = 'useOutbound998SetCancellation',
  UseOutboundRegionPostingStrategy = 'useOutboundRegionPostingStrategy',
  UseRmisV13 = 'useRmisV13',
  UseSearchFacilitiesByAddressApi = 'useSearchFacilitiesByAddressApi',
  UseX12MessageCompatible = 'useX12MessageCompatible',
  ValidationEntityType = 'validationEntityType',
  ValidationRequirements = 'validationRequirements',
  ValidationState = 'validationState',
  VehicleMake = 'vehicle make',
  VehicleModel = 'vehicle model',
  VendorInvoiceExceptionReason = 'vendorInvoiceExceptionReason',
  VendorInvoiceStatus = 'vendorInvoiceStatus',
  VendorType = 'vendorType',
  ViewApprovedUsersManualAutoAssociation = 'viewApprovedUsersManualAutoAssociation',
  ViewCarrierPortfolioROE = 'viewCarrierPortfolioROE',
  ViewConnectionStationMinionTab = 'viewConnectionStationMinionTab',
  ViewCustomerPortfolioROE = 'viewCustomerPortfolioROE',
  ViewDataDictionaryMinionTab = 'viewDataDictionaryMinionTab',
  ViewDisableAutoPostingCheckbox = 'viewDisableAutoPostingCheckbox',
  ViewEfsConnectionStation = 'viewEfsConnectionStation',
  ViewFeatureFlagMinionTab = 'viewFeatureFlagMinionTab',
  ViewGlobalCustomerPpwSettingChange = 'viewGlobalCustomerPpwSettingChange',
  ViewGlobalCustomerRequiredPaperwork = 'viewGlobalCustomerRequiredPaperwork',
  ViewGlobalVariableMinionTab = 'viewGlobalVariableMinionTab',
  ViewGlobalVendorPpwSettingChange = 'viewGlobalVendorPpwSettingChange',
  ViewGlobalVendorRequiredPaperwork = 'viewGlobalVendorRequiredPaperwork',
  ViewMarketPriceCardRateQuote = 'viewMarketPriceCardRateQuote',
  ViewMarketPriceCardSpotQuote = 'viewMarketPriceCardSpotQuote',
  ViewMarketPriceCardTruckEntry = 'viewMarketPriceCardTruckEntry',
  ViewMinionHomePage = 'viewMinionHomePage',
  ViewMultirouteListScreenUpdates = 'viewMultirouteListScreenUpdates',
  ViewOfferMarketPriceCard = 'viewOfferMarketPriceCard',
  ViewPortfolioInteractionsLowerTab = 'viewPortfolioInteractionsLowerTab',
  ViewPortfolioOpportunitiesLowerTab = 'viewPortfolioOpportunitiesLowerTab',
  ViewRmisMainAddressDesignation = 'viewRmisMainAddressDesignation',
  ViewRouteDetailsMarketPriceCard = 'viewRouteDetailsMarketPriceCard',
  ViewTransfloConnectionStation = 'viewTransfloConnectionStation',
  ViewUserOrganizations = 'viewUserOrganizations',
  ViewValidationConfigTable = 'viewValidationConfigTable',
  VoucherHoldEvents = 'voucherHoldEvents',
  WaybillStatus = 'waybillStatus',
  // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
  viewDownloadNotes = 'downloadCrmNotes',
  PowerCarrierRelationshipType = 'powerCarrierRelationshipType',
  TrailerEquipmentType = 'trailerEquipmentType',
  TrailerGeneralEquipmentCode = 'trailerGeneralEquipmentCode',
  ContactSource = 'contactSource',
  DefaultBinCheckbox = 'defaultBinCheckbox',
  DefaultDriverAssignmentType = 'defaultDriverAssignmentType',
  FuelProgramBasedOn = 'fuelProgramBasedOn',
  FuelProgramRegionalRelationship = 'fuelProgramRegionalRelationship',
  ViewPortfolioCalendarTab = 'viewPortfolioCalendarTab',
  QuoteFuelConfiguration = 'quoteFuelConfiguration',
  RouteSplitPurpose = 'routeSplitPurpose',
  DistanceUnit = 'distanceUnit',
  ExternallyMasteredNonCommodityOrderDrawerLock = 'externallyMasteredNonCommodityOrderDrawerLock',
  ExternallyMasteredStopDrawerLock = 'externallyMasteredStopDrawerLock',
  ExternallyMasteredCommodityLock = 'externallyMasteredCommodityLock',
  ExternallyMasteredMoneyDetailLock = 'externallyMasteredMoneyDetailLock',
  ViewCustomerCrmFunctionality = 'viewCustomerCrmFunctionality',
  ViewCarrierCrmFunctionaility = 'viewCarrierCrmFunctionality',
  RestrictSpecialCharactersCarrierCode = 'restrictSpecialCharactersCarrierCode',
  DefaultOutboundTenderSettingsRollover = 'defaultOutboundTenderSettingsRollover',
  DefaultOutboundTenderSettingsMaximumRolloverTime = 'defaultOutboundTenderSettingsMaximumRolloverTime',
  DefaultOutboundTenderSettingsAutomatedTendering = 'defaultOutboundTenderSettingsAutomatedTendering',
  DefaultOutboundTenderSettingsAppointmentStatusRequired = 'defaultOutboundTenderSettingsAppointmentStatusRequired',
  DriverPreferenceCareerGoalsWithinTimeFrame = 'driverPreferenceCareerGoalsWithinTimeFrame',
  DriverHOSDutyStatus = 'driverHOSDutyStatus',
  NextAvailablePreferenceReason = 'nextAvailablePreferenceReason',
  RequireScacOnRateAndSpotQuote = 'requireScacOnRateAndSpotQuote',
  CarrierRatingBasedOn = 'carrierRatingBasedOn',
  CustomerRatingBasedOn = 'customerRatingBasedOn',
  MergeRateConfirmationDocuments = 'mergeRateConfirmationDocuments',
  ValidationHandlingMethod = 'validationHandlingMethod',
  RoutingRequestStatus = 'RoutingRequestStatus',
  RoutingRequestType = 'RoutingRequestType',
  RoutingRequestLocationType = 'RoutingRequestLocationType',
  AssociateToBillingOrder = 'associateAsBackhaul',
  ManualOrderRateConfirmation = 'manualOrderRateConfirmation',
  ViewCustomerStructureCustomerRelationshipsHierarchy = 'viewCustomerStructureCustomerRelationshipsHierarchy',
  ViewCarrierStructureCarrierRelationshipsHierarchy = 'viewCarrierStructureCarrierRelationshipsHierarchy',
  RoutingRequestAllowLocationFieldsOptional = 'driverRoutingRequestLocationOptional',
  EnableDriverExternalAccess = 'enableDriverExternalAccess',
  OrderDefaultsSCAC = 'orderDefaultsScac',
  OrderDefaultsMode = 'orderDefaultsMode',
  OrderDefaultsSize = 'orderDefaultsSize',
  OrderDefaultsEquipment = 'orderDefaultsEquipment',
  OrderDefaultsCargoInsurance = 'orderDefaultsCargoInsurance',
  OrderDefaultsMinL = 'orderDefaultsMinL',
  OrderDefaultsMinW = 'orderDefaultsMinW',
  OrderDefaultsMinH = 'orderDefaultsMinH',
  OrderDefaultsDivision = 'orderDefaultsDivision',
  DelayedTrackingUpdateWarningThreshold = 'delayedTrackingUpdateWarningThreshold',
  CustomerCascadeHideContacts = 'customerCascadeHideContacts',
  CustomerCascadeContactsCheckbox = 'customerCascadeContactsCheckbox',
  CustomerFuelProgramType = 'fuelProgramType',
  CarrierFuelProgramType = 'carrierFuelProgramType',
  PowerLastPingDisplayWarningCadence = 'powerLastPingDisplayWarningCadence',
  CarrierCascadeHideContacts = 'carrierCascadeHideContacts',
  AbilityToChangeCustomerEDIOrders = 'abilityToChangeCustomerEdiOrders',
  CarrierCascadeContactsCheckbox = 'carrierCascadeContactsCheckbox',
  RunEtaPhosValidationsInAssignDriverFlow = 'runEtaPhosValidationsInAssignDriverFlow',
  NetworkType = 'networkType',
  CarrierFinalizedFuelStatus = 'carrierFinalizedFuelStatus',
  EarlyEtaStatusMinutes = 'earlyEtaStatusMinutes',
  VoucherMethod = 'voucherMethod',
  EmploymentType = 'employmentType',
  OperationalTypes = 'operationalTypes',
  AllowEditingOfStopEventsUpdatedExternally = 'allowEditingOfStopEventsUpdatedExternally',
  AbilityToAddExternalOrderId = 'abilityToAddExternalOrderId',
  ManagePayableDistance = 'managePayableDistance',
  CanvasMatchCriteria = 'canvasMatchCriteria',
  OrderFinancialStatusRequirements = 'orderFinancialStatusRequirements',
  TenderEligibility = 'tenderEligibility',
  CarrierCascadeComplianceFields = 'carrierCascadeComplianceFields',
  ComdataCardStatus = 'comdataCardStatus',
  AccountingImportType = 'accountingImportType',
  AccountingImportApplicationMethod = 'accountingImportApplicationMethod',
  MonthlyFuelBasedOn = 'monthlyFuelBasedOn',
  FuelWeeks = 'fuelWeeksApplication',
  ActivationStatusIncompleteReason = 'activationStatusIncompleteReason',
  ValidateDriverFleetRelationshipRoutes = 'validateDriverFleetRelationshipRoutes',
  TrailerLastPingDisplayWarningCadence = 'trailerLastPingDisplayWarningCadence',
  DriverCardProductType = 'driverCardProductType',
  EnableReportsMenu = 'enableReportsMenu',
  DriverRelationshipType = 'driverRelationshipType',
  DriverRelationshipStatus = 'driverRelationshipStatus',
  driverRelationshipPlannedEndReason = 'driverRelationshipPlannedEndReason',
  EnableDashboards = 'enableDashboards',
  DriverMessagingMessageCentricView = 'driverMessagingMessageCentricView',
  manualBillOfLading = 'manualBillOfLading',
  LeadTimeOfferApprovalAutomation = 'LeadTimeOfferApprovalAutomation',
  dayOfTheWeek = 'dayOfTheWeek',
  automatedBolDocumentGenerationFtl = 'automatedBolDocumentGenerationFtl',
  automatedBolDocumentGenerationLtlPartial = 'automatedBolDocumentGenerationLtlPartial',
}
